import React from 'react';
import PropTypes from 'prop-types';

const TeacherAndWhiteBoard = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.63014C0 1.17755 1.17755 0 2.63014 0H21.3699C22.8224 0 24 1.17755 24 2.63014V16.4384C24 17.8909 22.8224 19.0685 21.3699 19.0685H15.4521V17.0959H21.3699C21.733 17.0959 22.0274 16.8015 22.0274 16.4384V2.63014C22.0274 2.26699 21.733 1.9726 21.3699 1.9726H2.63014C2.26699 1.9726 1.9726 2.26699 1.9726 2.63014V13.0685H0V2.63014Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.39705 9.86299C8.12334 9.86299 8.71211 9.27422 8.71211 8.54792C8.71211 7.82163 8.12334 7.23286 7.39705 7.23286C6.67075 7.23286 6.08198 7.82163 6.08198 8.54792C6.08198 9.27422 6.67075 9.86299 7.39705 9.86299ZM7.39705 11.8356C9.21278 11.8356 10.6847 10.3637 10.6847 8.54792C10.6847 6.73219 9.21278 5.26025 7.39705 5.26025C5.58132 5.26025 4.10938 6.73219 4.10938 8.54792C4.10938 10.3637 5.58132 11.8356 7.39705 11.8356Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2885 13.8904L17.7469 7.57422L19.2394 8.86403L13.726 15.2438V22.4383H0V17.9178C0 16.4334 0.729635 15.3938 1.61206 14.7584C2.4553 14.1513 3.46024 13.8904 4.19178 13.8904H12.2885ZM11.7534 15.863H4.19178C3.85483 15.863 3.25703 16.0048 2.76466 16.3593C2.31146 16.6856 1.9726 17.1665 1.9726 17.9178V20.4657H11.7534V15.863Z"
      fill="currentColor"
    />
  </svg>
);

TeacherAndWhiteBoard.propTypes = {
  className: PropTypes.string,
};

TeacherAndWhiteBoard.defaultProps = {
  className: '',
};

export default TeacherAndWhiteBoard;
