import React from 'react';
import PropTypes from 'prop-types';

const LeftArrowIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.26796 0.455745L6.4375 1.75523L3.09597 5.46805L6.4375 9.18086L5.26796 10.4803L0.756893 5.46805L5.26796 0.455745Z"
      fill="#2D3942"
    />
  </svg>
);

LeftArrowIcon.propTypes = {
  className: PropTypes.string,
};

LeftArrowIcon.defaultProps = {
  className: '',
};

export default LeftArrowIcon;
