import React from 'react';
import PropTypes from 'prop-types';

const BlogIcon = ({ className, ...rest }) => (
  <svg className={className} {...rest} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 29 23">
    <path
      d="M31.6666 10.5778L26.4482 5.34074C26.2416 5.13333 25.9832 5.02963 25.7249 5.02963C25.4666 5.02963 25.2082 5.13333 25.0016 5.34074L24.7432 5.54815V1.03704C24.7432 0.466667 24.2782 0 23.7099 0H2.0099C1.44156 0 0.976562 0.466667 0.976562 1.03704V26.963C0.976562 27.5333 1.44156 28 2.0099 28H23.7099C24.2782 28 24.7432 27.5333 24.7432 26.963V19.0296L31.6666 12.0815C32.0799 11.6667 32.0799 10.9926 31.6666 10.5778ZM22.6766 25.9259H3.04323V2.07407H22.6766V7.67407L14.6166 15.7111C14.4099 15.9185 14.3066 16.2296 14.3066 16.4889L14.5649 21.4667C14.6166 21.9852 15.0299 22.4 15.5466 22.4519L20.5066 22.7111H20.5582C20.8166 22.7111 21.0749 22.6074 21.2816 22.4L22.6249 21.0519V25.9259H22.6766ZM20.1966 20.637L16.5799 20.4296L16.3732 16.8L25.6732 7.46667L29.4449 11.2519L20.1966 20.637ZM18.3882 8.81481H7.33156C6.76323 8.81481 6.29823 8.34815 6.29823 7.77778C6.29823 7.20741 6.76323 6.74074 7.33156 6.74074H18.3882C18.9566 6.74074 19.4216 7.20741 19.4216 7.77778C19.4216 8.34815 18.9566 8.81481 18.3882 8.81481Z"
      fill="#979797"
    />
  </svg>
);

BlogIcon.propTypes = {
  className: PropTypes.string,
};

BlogIcon.defaultProps = {
  className: '',
};

export default BlogIcon;
