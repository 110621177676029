import React from 'react';
import PropTypes from 'prop-types';

const Church = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49 11H59V6C59 3.2385 56.7615 1 54 1C51.2385 1 49 3.2385 49 6V11Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54 2H13.7872C11.1432 2 9 4.1164 9 6.72727V54H44.4255C47.0695 54 49.2128 51.8836 49.2128 49.2727V6.72727C49.2128 4.1164 51.356 2 54 2Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54 2H13.7872C11.1432 2 9 4.1164 9 6.72727V54H44.4255C47.0695 54 49.2128 51.8836 49.2128 49.2727V6.72727C49.2128 4.1164 51.356 2 54 2Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.56 29H17.44C16.6446 29 16 28.3285 16 27.5C16 26.6715 16.6446 26 17.44 26H38.56C39.3554 26 40 26.6715 40 27.5C40 28.3285 39.3554 29 38.56 29Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.6 15H22.4C21.6267 15 21 14.3285 21 13.5C21 12.6715 21.6267 12 22.4 12H33.6C34.3733 12 35 12.6715 35 13.5C35 14.3285 34.3733 15 33.6 15Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.5918 36H17.4082C16.6304 36 16 35.3285 16 34.5C16 33.6715 16.6304 33 17.4082 33H37.5918C38.3696 33 39 33.6715 39 34.5C39 35.3285 38.3696 36 37.5918 36Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.2564 49V44H7V49C7 51.7615 9.12371 54 11.7436 54H44C41.3801 54 39.2564 51.7615 39.2564 49Z"
      fill="white"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="0" width="52" height="55">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 0H60V55H8V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.8545 0H14.1455C10.7569 0 8 2.76517 8 6.16379V11.3793C8 12.165 8.63487 12.8017 9.41818 12.8017C10.2015 12.8017 10.8364 12.165 10.8364 11.3793V6.16379C10.8364 4.33362 12.3207 2.84483 14.1455 2.84483H48.6782C48.0651 3.80353 47.7091 4.94289 47.7091 6.16379V48.8362C47.7091 50.6664 46.2247 52.1552 44.4 52.1552C42.5753 52.1552 41.0909 50.6664 41.0909 48.8362V44.0948C41.0909 43.3092 40.456 42.6724 39.6727 42.6724H32.5818C31.7985 42.6724 31.1636 43.3092 31.1636 44.0948C31.1636 44.8805 31.7985 45.5172 32.5818 45.5172H38.2545V48.8362C38.2545 50.0571 38.6105 51.1965 39.2236 52.1552H31.1636C30.3803 52.1552 29.7455 52.7919 29.7455 53.5776C29.7455 54.3632 30.3803 55 31.1636 55H44.4C47.7885 55 50.5455 52.2348 50.5455 48.8362V6.16379C50.5455 4.33362 52.0298 2.84483 53.8545 2.84483C55.6793 2.84483 57.1636 4.33362 57.1636 6.16379V9.48276H53.8545C53.0712 9.48276 52.4364 10.1195 52.4364 10.9052C52.4364 11.6908 53.0712 12.3276 53.8545 12.3276H58.5818C59.3651 12.3276 60 11.6908 60 10.9052V6.16379C60 2.76517 57.2431 0 53.8545 0Z"
        fill="#444B54"
      />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M25 43V58L16 52.375L7 58V43" fill="#FF5576" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 31.5C30 39.5079 23.5079 46 15.5 46C7.49213 46 1 39.5079 1 31.5C1 23.4921 7.49213 17 15.5 17C23.5079 17 30 23.4921 30 31.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.81818 31.7827C2.81818 24.6623 8.50715 18.8696 15.5 18.8696C22.4928 18.8696 28.1818 24.6623 28.1818 31.7827C28.1818 38.903 22.4928 44.6957 15.5 44.6957C8.50715 44.6957 2.81818 38.903 2.81818 31.7827ZM31 31.7826C31 23.0802 24.0466 16 15.5 16C6.95339 16 0 23.0802 0 31.7826C0 40.485 6.95339 47.5652 15.5 47.5652C18.2257 47.5652 20.7874 46.8426 23.0152 45.5795V55.962L16.2525 51.6127C15.7931 51.3172 15.2069 51.3172 14.7475 51.6127L7.98485 55.962V48.0435C7.98485 47.251 7.35405 46.6087 6.57576 46.6087C5.79747 46.6087 5.16667 47.251 5.16667 48.0435V58.5652C5.16667 59.088 5.44614 59.5696 5.89564 59.8216C6.34514 60.0741 6.89374 60.0574 7.32821 59.7786L15.5 54.523L23.6718 59.7786C23.901 59.9259 24.1622 60 24.4242 60C24.6582 60 24.8925 59.9407 25.1044 59.8216C25.5539 59.5696 25.8333 59.088 25.8333 58.5652V43.5335C29.0019 40.6419 31 36.4461 31 31.7826Z"
      fill="#444B54"
    />
  </svg>
);

Church.propTypes = {
  className: PropTypes.string,
};

Church.defaultProps = {
  className: '',
};

export default Church;
