import React from 'react';
import PropTypes from 'prop-types';

const LinkedIn = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} className={className} viewBox="0 0 12 13" fill="none">
    <path
      d="M1.56816 0.799805C0.729077 0.799805 0.0458984 1.48298 0.0458984 2.32207C0.0458984 3.16115 0.729077 3.84433 1.56816 3.84433C2.40724 3.84433 3.09042 3.16115 3.09042 2.32207C3.09042 1.48298 2.40724 0.799805 1.56816 0.799805ZM8.7643 4.39788C7.75281 4.39788 7.14428 4.89952 6.68849 5.41416V4.81304C6.68849 4.74058 6.62256 4.67466 6.55011 4.67465H4.33591C4.26345 4.67466 4.19753 4.74058 4.19752 4.81304V12.286C4.19753 12.3584 4.26345 12.4243 4.33591 12.4243H6.68849C6.76095 12.4243 6.82687 12.3584 6.82688 12.286V8.13434C6.82688 7.75603 6.9442 7.40077 7.12528 7.14833C7.30636 6.89588 7.5423 6.75046 7.79559 6.75046H8.07237C8.32567 6.75046 8.5616 6.89588 8.74268 7.14833C8.92376 7.40077 9.04108 7.75603 9.04108 8.13434V12.286C9.04109 12.3584 9.10701 12.4243 9.17947 12.4243H11.5321C11.6045 12.4243 11.6704 12.3584 11.6704 12.286V7.4424C11.6704 6.5375 11.4968 5.77344 11.0347 5.23253C10.5726 4.69162 9.83218 4.39788 8.7643 4.39788ZM0.435113 4.67465C0.371676 4.68676 0.321535 4.74847 0.322673 4.81304V12.286C0.32268 12.3584 0.388603 12.4243 0.461061 12.4243H2.81365C2.8861 12.4243 2.95203 12.3584 2.95203 12.286V4.81304C2.95203 4.74058 2.8861 4.67466 2.81365 4.67465C2.02164 4.67465 1.22512 4.67465 0.435113 4.67465Z"
      fill="currentColor"
    />
  </svg>
);

LinkedIn.propTypes = {
  className: PropTypes.string,
};

LinkedIn.defaultProps = {
  className: '',
};

export default LinkedIn;
