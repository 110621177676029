import React from 'react';
import PropTypes from 'prop-types';

const TimeClockIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8C16 12.4 12.4 16 8 16C3.6 16 0 12.4 0 8ZM2 8C2 11.3 4.7 14 8 14C11.3 14 14 11.3 14 8C14 4.7 11.3 2 8 2C4.7 2 2 4.7 2 8Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 4H7V9H12V7H9V4Z" fill="currentColor" />
  </svg>
);

TimeClockIcon.propTypes = {
  className: PropTypes.string,
};

TimeClockIcon.defaultProps = {
  className: '',
};

export default TimeClockIcon;
