import React from 'react';
import PropTypes from 'prop-types';

const ArrowThinFW = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9H18" stroke="#2D3942" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 17V1L29 9L18 17Z"
      stroke="#2D3942"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

ArrowThinFW.propTypes = {
  className: PropTypes.string,
};

ArrowThinFW.defaultProps = {
  className: '',
};

export default ArrowThinFW;
