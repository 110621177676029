import React from 'react';
import PropTypes from 'prop-types';

const PricingAndSchedule = ({ className, ...rest }) => (
  <svg className={className} {...rest} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      fill="#9B9B9B"
      fillRule="evenodd"
      d="M17 1h-1V0h-2v1H6V0H4v1H3C1.3 1 0 2.3 0 4v13c0 1.7 1.3 3 3 3h3v-2H3c-.6 0-1-.4-1-1V4c0-.6.4-1 1-1h1v2h2V3h8v2h2V3h1c.6 0 1 .4 1 1v13c0 .6-.4 1-1 1h-3v2h3c1.7 0 3-1.3 3-3V4c0-1.7-1.3-3-3-3z"
      clipRule="evenodd"
    />
    <path
      fill="#9B9B9B"
      fillRule="evenodd"
      d="M10 11c.6 0 1 .4 1 1h2c0-1.3-.8-2.4-2-2.8V8H9v1.2c-1.2.4-2 1.5-2 2.8 0 1.7 1.3 3 3 3 .6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1H7c0 1.3.8 2.4 2 2.8V20h2v-1.2c1.2-.4 2-1.5 2-2.8 0-1.7-1.3-3-3-3-.6 0-1-.4-1-1s.4-1 1-1z"
      clipRule="evenodd"
    />
  </svg>
);

PricingAndSchedule.propTypes = {
  className: PropTypes.string,
};

PricingAndSchedule.defaultProps = {
  className: '',
};

export default PricingAndSchedule;
