import React from 'react';
import PropTypes from 'prop-types';

const Search = ({ className, ...rest }) => (
  <svg {...rest} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.4883 23L17.9988 17.5105" stroke="#5D6A74" strokeWidth="1.2" strokeLinecap="square" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9883 20C16.235 20 20.4883 15.7467 20.4883 10.5C20.4883 5.25329 16.235 1 10.9883 1C5.74158 1 1.48828 5.25329 1.48828 10.5C1.48828 15.7467 5.74158 20 10.9883 20Z"
      stroke="#5D6A74"
      strokeWidth="1.2"
      strokeLinecap="square"
    />
  </svg>
);

Search.propTypes = {
  className: PropTypes.string,
};

Search.defaultProps = {
  className: '',
};

export default Search;
