import React from 'react';
import PropTypes from 'prop-types';

const DownloadIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.69085 4.3048H8.97656L4.97656 8.42245L0.976562 4.3048H3.26228V0.775391H6.69085V4.3048ZM0.976562 10.7754V9.59892H8.97656V10.7754H0.976562Z"
    />
  </svg>
);

DownloadIcon.propTypes = {
  className: PropTypes.string,
};

DownloadIcon.defaultProps = {
  className: '',
};

export default DownloadIcon;
