import React from 'react';
import PropTypes from 'prop-types';

const TwoFatGuys = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5758 8.93919C12.8485 8.14865 12 7.54054 11.0909 7.05405C11.7576 6.32432 12.1212 5.35135 12.1212 4.25676C12.1212 1.88513 10.2424 0 7.87879 0C5.51515 0 3.63636 1.88513 3.63636 4.25676C3.63636 5.29054 4.06061 6.26351 4.66667 7.05405C1.87879 8.39189 0 11.25 0 14.5338C0 15.0203 0.424242 15.4459 0.909091 15.4459C1.39394 15.4459 1.81818 15.0811 1.81818 14.5338C1.81818 11.0068 4.54545 8.14865 7.81818 8.14865C9.51515 8.14865 10.9697 8.87838 12.0606 10.0338C12.5455 9.66892 13.0303 9.24324 13.5758 8.93919ZM7.81818 6.75C6.48485 6.75 5.39394 5.6554 5.39394 4.31757C5.39394 2.97973 6.48485 1.88513 7.81818 1.88513C9.15152 1.88513 10.2424 2.97973 10.2424 4.31757C10.2424 5.6554 9.15152 6.75 7.81818 6.75ZM21.3939 9.5473C22.0606 8.81757 22.4242 7.84459 22.4242 6.81081C22.4242 4.43919 20.5455 2.55405 18.1818 2.55405C15.8182 2.55405 13.9394 4.43919 13.9394 6.81081C13.9394 7.84459 14.303 8.81757 14.9697 9.60811C12.2424 10.8851 10.3636 13.7432 10.3636 17.0878C10.3636 17.5743 10.7879 18 11.2727 18C11.7576 18 12.1818 17.5743 12.1818 17.0878C12.1818 13.5608 14.9091 10.7027 18.1818 10.7027C21.4545 10.7027 24.1818 13.5608 24.1818 17.0878C24.1818 17.5743 24.6061 18 25.0909 18C25.5758 18 26 17.5743 26 17.0878C26 13.6824 24.1212 10.8243 21.3939 9.5473ZM18.1818 9.24324C16.8485 9.24324 15.7576 8.14865 15.7576 6.81081C15.7576 5.47297 16.8485 4.37838 18.1818 4.37838C19.5152 4.37838 20.6061 5.47297 20.6061 6.81081C20.6061 8.14865 19.5152 9.24324 18.1818 9.24324Z"
      fill="currentColor"
    />
  </svg>
);

TwoFatGuys.propTypes = {
  className: PropTypes.string,
};

TwoFatGuys.defaultProps = {
  className: '',
};

export default TwoFatGuys;
