import React from 'react';
import PropTypes from 'prop-types';

const CircleCheck = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23.5" cy="23.5" r="22" stroke="currentColor" strokeWidth="3" />
    <path d="M16 23.8421L22.2963 30L33 17" stroke="currentColor" strokeWidth="4" />
  </svg>
);

CircleCheck.propTypes = {
  className: PropTypes.string,
};

CircleCheck.defaultProps = {
  className: '',
};

export default CircleCheck;
