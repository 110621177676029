import React from 'react';
import PropTypes from 'prop-types';

const DoubleNext = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.56231 13.5128L2.56204 13.513C2.28465 13.7533 1.93678 13.8751 1.54856 13.8751C1.16035 13.8751 0.812486 13.7533 0.535091 13.513C0.258404 13.2734 0.125028 12.98 0.125028 12.6589C0.125028 12.3425 0.292243 12.0151 0.572861 11.7721L5.97386 7.09453L6.08258 7.00036L5.97418 6.90583L0.573187 2.19553L0.572862 2.19525C0.296173 1.95562 0.162797 1.66218 0.162797 1.34114C0.162797 1.02009 0.296173 0.726653 0.572862 0.487025C0.850255 0.246785 1.19812 0.12501 1.58634 0.12501C1.97455 0.12501 2.32241 0.246785 2.59981 0.487026L9.13388 6.14592C9.41057 6.38555 9.54395 6.67899 9.54395 7.00004C9.54395 7.32101 9.41063 7.61439 9.13406 7.85399C9.134 7.85404 9.13394 7.8541 9.13388 7.85415L2.56231 13.5128Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.25"
    />
    <path
      d="M10.5623 13.5128L10.562 13.513C10.2846 13.7533 9.93678 13.8751 9.54856 13.8751C9.16035 13.8751 8.81248 13.7533 8.53509 13.513C8.2584 13.2734 8.12503 12.98 8.12503 12.6589C8.12503 12.3425 8.29224 12.0151 8.57286 11.7721L13.9739 7.09453L14.0826 7.00036L13.9742 6.90583L8.57319 2.19553L8.57286 2.19525C8.29617 1.95562 8.1628 1.66218 8.1628 1.34114C8.1628 1.02009 8.29617 0.726653 8.57286 0.487025C8.85026 0.246785 9.19812 0.12501 9.58633 0.12501C9.97455 0.12501 10.3224 0.246785 10.5998 0.487026L17.1339 6.14592C17.4106 6.38555 17.5439 6.67899 17.5439 7.00004C17.5439 7.32101 17.4106 7.61439 17.1341 7.85399C17.134 7.85404 17.1339 7.8541 17.1339 7.85415L10.5623 13.5128Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.25"
    />
  </svg>
);

DoubleNext.propTypes = {
  className: PropTypes.string,
};

DoubleNext.defaultProps = {
  className: '',
};

export default DoubleNext;
