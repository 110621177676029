import React from 'react';
import PropTypes from 'prop-types';

const Menu = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 23 17" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0057 7.80664H1.37216C0.930018 7.80664 0.635254 8.06749 0.635254 8.45877C0.635254 8.85005 0.930018 9.1109 1.37216 9.1109H22.0057C22.4478 9.1109 22.7426 8.85005 22.7426 8.45877C22.7426 8.06749 22.4478 7.80664 22.0057 7.80664Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0057 0H1.37216C0.930018 0 0.635254 0.260852 0.635254 0.65213C0.635254 1.04341 0.930018 1.30426 1.37216 1.30426H22.0057C22.4478 1.30426 22.7426 1.04341 22.7426 0.65213C22.7426 0.260852 22.4478 0 22.0057 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0057 15.6133H1.37216C0.930018 15.6133 0.635254 15.8741 0.635254 16.2654C0.635254 16.6567 0.930018 16.9176 1.37216 16.9176H22.0057C22.4478 16.9176 22.7426 16.6567 22.7426 16.2654C22.7426 15.8741 22.4478 15.6133 22.0057 15.6133Z"
      fill="currentColor"
    />
  </svg>
);

Menu.propTypes = {
  className: PropTypes.string,
};

Menu.defaultProps = {
  className: '',
};

export default Menu;
