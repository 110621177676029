import React from 'react';
import PropTypes from 'prop-types';

const ArrowThinBW = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29 9H12" stroke="#2D3942" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1V17L1 9L12 1Z"
      stroke="#2D3942"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

ArrowThinBW.propTypes = {
  className: PropTypes.string,
};

ArrowThinBW.defaultProps = {
  className: '',
};

export default ArrowThinBW;
