import React from 'react';
import PropTypes from 'prop-types';

const Reading = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9999 5.6001V19.2001" stroke="currentColor" strokeWidth="1.6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 4C14.0455 4 12 5.04 12 6.4C12 5.04 9.95455 4 7.5 4C5.04545 4 3 5.04 3 6.4V20C3 18.64 5.04545 17.6 7.5 17.6C9.95455 17.6 12 18.64 12 20C12 18.64 14.0455 17.6 16.5 17.6C18.9545 17.6 21 18.64 21 20V6.4C21 5.04 18.9545 4 16.5 4Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="square"
    />
  </svg>
);

Reading.propTypes = {
  className: PropTypes.string,
};

Reading.defaultProps = {
  className: '',
};

export default Reading;
