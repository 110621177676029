import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as icons from './SVGIcons';

const Wrapper = styled.i`
  display: inline-block;
  text-align: center;
  line-height: 0;
  vertical-align: -0.125em;
`;

function Icon({ type, className, ...rest }) {
  const SVGIcon = icons[type];

  return (
    <Wrapper className={className}>
      {SVGIcon ? <SVGIcon width="1em" height="1em" {...rest} /> : `Missing icon ${type}`}
    </Wrapper>
  );
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
};

export default Icon;
