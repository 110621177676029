import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Link, navigate } from 'gatsby';

import {
  DropdownIcon,
  findMyName,
  NavigableSubMenu,
  UnnavigableSubMenu,
  isNotNavigableMenu,
  isNavigableMenu,
  hasChildren,
} from './utils';
import menus from '../../content/menus';
import Icon from '../Icon';
import LogoWhite from './LogoWhite';
import LogoPink from './LogoPink';
import Lang from './Lang';
import UserDropdown from '../Layout/AuthLayout/UserDropdown';

const headerPadding = '20px';
export const menuHeightMobile = '54px';

const Main = styled.div`
  margin: 0 auto;
  height: ${menuHeightMobile};
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 14px;
`;

const Sidebar = styled.div`
  min-height: ${menuHeightMobile};
  display: flex;
  align-items: center;
  padding: 0 ${headerPadding};
  justify-content: space-between;
  background-color: ${p => (p.collapsed ? 'transparent' : 'white')};
`;

const NavExpanded = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${props => props.theme.white};
  width: 100%;
  height: ${props => (props.show ? `calc(100vh - ${menuHeightMobile})` : 0)};
  overflow-y: scroll;
  padding: 0 ${headerPadding};
  overflow-x: hidden;
  top: ${menuHeightMobile};
`;

const MenuIcon = styled(Icon).attrs({ type: 'Menu' })`
  color: white;
  font-size: 1.375rem;
`;

const CloseIcon = styled(Icon).attrs({ type: 'CloseIcon' })`
  font-size: 1.375rem;
  color: black;
`;

const SocialIcon = styled(Icon)`
  vertical-align: sub;

  &:not(:last-child) {
    margin-right: 1em;
  }
`;

const Toggle = styled.button`
  outline: 0;
  border: 0;
  appearance: unset;
  background-color: transparent;
`;

const LanguageBar = styled(Lang).attrs({ mobile: true })`
  margin-bottom: 1em;
`;

const InfoWrapper = styled.div`
  background-color: white;
  padding: 20px 0;
  width: calc(100vw - ${headerPadding} * 2);

  ${props =>
    props.isStickBottom &&
    css`
      position: fixed;
      bottom: 0;
    `}
`;

const InfoText = styled.span`
  color: #939393;
`;

const InfoLine = styled.p`
  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const SocialIcons = styled.span`
  text-align: right;
  color: ${props => props.theme.brand};
  margin-left: 1em;

  @media screen and (max-width: 425px) {
    float: right;
  }
`;

const Pricing = styled.span`
  font-size: 1em;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.white};

  @media screen and (max-width: 425px) {
    font-size: 0.7em;

    ${p =>
      p.expanded &&
      css`
        padding: 0.4em 0.8em;
        border-radius: 2px;
      `}
  }

  &:not(:first-child) {
    margin-left: 0.7em;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ${props =>
    props.expanded &&
    css`
      background-color: ${props.theme.brand};
      padding: 5px 7px;
      border-radius: 2px;
    `}
`;

const Login = styled(Pricing)`
  ${props =>
    props.expanded &&
    css`
      color: black;
      padding: 0;
      background-color: transparent;
    `}
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.navItemTextColor};
  text-decoration: none;
`;

const RootMenus = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  justify-content: flex-start;
`;

const Menu = styled.div`
  display: block;
  border-bottom: 1px solid #f7f6f6;
`;

const RootMenuName = styled.div`
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  a {
    color: inherit;
    text-decoration: inherit;
  }
`;

const BlackDropdownIcon = styled(DropdownIcon)`
  color: black;
`;

const NavigableSubMenuMobile = styled(NavigableSubMenu)`
  margin: 30px 0;
`;

const SubMenus = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};

  ${props =>
    props.visible &&
    props.isColumns &&
    css`
      display: flex;
      flex-flow: row wrap;
    `}
`;
const noMenuSelectedYet = menu => menu === null;
const isClickTheSameMenu = (menu1, menu2) => menu1 === menu2;
const sortByOrderProperty = (menu1, menu2) =>
  menu1.mobileOrder ? menu1.mobileOrder - menu2.mobileOrder : menu1.order - menu2.order;

const UnnavigableSubMenuMobile = styled(UnnavigableSubMenu)`
  margin: 10px 0 25px 0;

  :last-child {
    margin-bottom: 10px;
  }
`;

const logoWidthDesktop = '3.3em';
const logoWidthMobile = '2.5em';

const Logo = styled(Link).attrs({ to: '/' })`
  position: absolute;
  width: ${logoWidthDesktop};
  left: calc(50% - ${logoWidthDesktop} / 2);

  @media screen and (max-width: 425px) {
    width: ${logoWidthMobile};
    left: calc(50% - ${logoWidthMobile} / 2);
  }
`;

const AuthorizedUser = styled(UserDropdown)`
  margin: 0 1em;

  a,
  div {
    color: black;
  }
`;

function MobileHeader({ user }) {
  const [collapsed, setCollapsed] = React.useState(true);
  const [selectedMenu, setSelectedMenu] = React.useState(null);

  const handleMenuTapped = tappedMenu => {
    if (isClickTheSameMenu(tappedMenu, selectedMenu)) {
      setSelectedMenu(null);
    } else if (isNavigableMenu(tappedMenu)) {
      if (hasChildren(tappedMenu)) {
        setSelectedMenu(tappedMenu);
      } else {
        setCollapsed(true);
        navigate(tappedMenu.href);
      }
    } else {
      setSelectedMenu(tappedMenu);
    }
  };

  return (
    <Main>
      <Sidebar collapsed={collapsed}>
        <Toggle onClick={() => setCollapsed(!collapsed)}>{collapsed ? <MenuIcon /> : <CloseIcon />}</Toggle>
        <Logo>{collapsed ? <LogoWhite /> : <LogoPink />}</Logo>
        <div>
          <Login expanded={!collapsed}>
            {user ? <AuthorizedUser user={user} avatarSize="small" /> : <Link to="/login">LOG IN</Link>}
          </Login>
          <Pricing expanded={!collapsed}>
            <Link to="/pricing">PRICING</Link>
          </Pricing>
        </div>
      </Sidebar>

      <NavExpanded show={!collapsed}>
        <RootMenus>
          {menus &&
            menus.map(root => (
              <Menu key={root.id} navigable={isNavigableMenu(root)} onClick={() => handleMenuTapped(root)}>
                <RootMenuName>
                  {/* Only show dropdown icon if itself has children */}
                  {root.href !== null && (
                    <>
                      {root.isExternal ? (
                        <a href={root.href} target="_blank" rel="noopener noreferrer">
                          {root.name}
                        </a>
                      ) : (
                        <StyledLink to={root.href}>{root.name}</StyledLink>
                      )}
                    </>
                  )}
                  {root.href === null && findMyName(root)}
                  {root.children.length > 0 && <BlackDropdownIcon />}
                </RootMenuName>
                <SubMenus
                  visible={selectedMenu === root}
                  isColumns={root.mobileColumns !== null && root.mobileColumns > 0}
                >
                  {root.children.length > 0 &&
                    root.children.sort(sortByOrderProperty).map(subMenu => (
                      <React.Fragment key={subMenu.id}>
                        {isNavigableMenu(subMenu) && <NavigableSubMenuMobile mobileVersion {...subMenu} />}
                        {isNotNavigableMenu(subMenu) && (
                          <UnnavigableSubMenuMobile
                            {...subMenu}
                            mobileVersion
                            columns={2}
                            name={subMenu.mobileName || subMenu.name}
                          />
                        )}
                      </React.Fragment>
                    ))}
                </SubMenus>
              </Menu>
            ))}
          <InfoWrapper isStickBottom={noMenuSelectedYet(selectedMenu) && !collapsed}>
            <LanguageBar />
            <InfoLine>
              <InfoText>Phone +84 976 537 368</InfoText>
              <SocialIcons>
                <SocialIcon type="Facebook" />
                <SocialIcon type="Instagram" />
                <SocialIcon type="LinkedIn" />
              </SocialIcons>
            </InfoLine>
            <InfoLine>
              <InfoText>51 Thanh Thai, Ward 5, District 10, HCMC</InfoText>
            </InfoLine>
          </InfoWrapper>
        </RootMenus>
      </NavExpanded>
    </Main>
  );
}

export default MobileHeader;

MobileHeader.propTypes = {
  user: PropTypes.shape({}),
};

MobileHeader.defaultProps = {
  user: null,
};
