import React from 'react';
import PropTypes from 'prop-types';

const LearningFasterWithQuizAndGame = ({ className, ...rest }) => (
  <svg className={className} {...rest} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 22">
    <path
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="2"
      d="M21 7V2c0-.6-.4-1-1-1h-3c-.6 0-1 .4-1 1 0 1.7-1.3 3-3 3s-3-1.3-3-3c0-.6-.4-1-1-1H6c-.6 0-1 .4-1 1v5c-2.2 0-4 1.8-4 4s1.8 4 4 4v5c0 .6.4 1 1 1h3c.6 0 1-.4 1-1 0-1.7 1.3-3 3-3s3 1.3 3 3c0 .6.4 1 1 1h3c.6 0 1-.4 1-1v-5c2.2 0 4-1.8 4-4s-1.8-4-4-4z"
      clipRule="evenodd"
    />
  </svg>
);

LearningFasterWithQuizAndGame.propTypes = {
  className: PropTypes.string,
};

LearningFasterWithQuizAndGame.defaultProps = {
  className: '',
};

export default LearningFasterWithQuizAndGame;
