import React from 'react';
import PropTypes from 'prop-types';

const GlyphsTeacher = ({ className, ...rest }) => (
  <svg {...rest} className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30">
    <path
      fillRule="evenodd"
      stroke="#607187"
      strokeLinecap="square"
      strokeWidth="2"
      d="M26 4H14v10l5-4h7V4zM13 24.836c0-.604-.265-1.179-.738-1.554C11.539 22.708 10.285 22 8.5 22s-3.039.708-3.762 1.282c-.473.375-.738.95-.738 1.554V26h9v-1.164zM8.5 19a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zM26 24.836c0-.604-.265-1.179-.738-1.554C24.539 22.708 23.285 22 21.5 22s-3.039.708-3.762 1.282c-.473.375-.738.95-.738 1.554V26h9v-1.164zM21.5 19a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
      clipRule="evenodd"
    />
  </svg>
);

GlyphsTeacher.propTypes = {
  className: PropTypes.string,
};

GlyphsTeacher.defaultProps = {
  className: '',
};

export default GlyphsTeacher;
