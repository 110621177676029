import React from 'react';
import PropTypes from 'prop-types';

const Minus = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 6 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.553524 2.04V0.24H5.97152V2.04H0.553524Z" fill="currentColor" />
  </svg>
);

Minus.propTypes = {
  className: PropTypes.string,
};

Minus.defaultProps = {
  className: '',
};

export default Minus;
