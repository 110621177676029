import React from 'react';
import PropTypes from 'prop-types';

const LockCircleIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 16 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 11.5463V6.82031C14 3.51131 11.309 0.820312 8 0.820312C4.691 0.820312 2 3.51131 2 6.82031V11.5463C0.759 12.9563 0 14.7993 0 16.8203C0 21.2313 3.589 24.8203 8 24.8203C12.411 24.8203 16 21.2313 16 16.8203C16 14.7993 15.241 12.9563 14 11.5463ZM9 18.6363V20.8203H7V18.6363C5.839 18.2223 5 17.1223 5 15.8203C5 14.1663 6.346 12.8203 8 12.8203C9.654 12.8203 11 14.1663 11 15.8203C11 17.1223 10.162 18.2223 9 18.6363ZM8 8.82031C9.458 8.82031 10.822 9.21831 12 9.90231V6.82031C12 4.61431 10.206 2.82031 8 2.82031C5.794 2.82031 4 4.61431 4 6.82031V9.90231C5.178 9.21831 6.542 8.82031 8 8.82031Z"
      fill="currentColor"
    />
  </svg>
);

LockCircleIcon.propTypes = {
  className: PropTypes.string,
};

LockCircleIcon.defaultProps = {
  className: '',
};

export default LockCircleIcon;
