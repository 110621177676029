import React from 'react';
import PropTypes from 'prop-types';

const EcosystemIcon = ({ className, ...rest }) => (
  <svg className={className} {...rest} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      strokeWidth="1.8"
      d="M2.913 3.813h6.396l.005.004a.015.015 0 0 1 .003.004v6.401a.016.016 0 0 1-.008.008h-.001H2.909c-.002 0-.003-.002-.005-.003a.016.016 0 0 1-.003-.005v-.001l-.001-.004v-6.39-.006l.004-.004a.015.015 0 0 1 .004-.003h.005zM17.979 2.923l4.098 4.099-4.098 4.098-4.099-4.098 4.099-4.099zM14.782 15.683H21.178l.005.004a.016.016 0 0 1 .003.004V22.091c0 .002-.002.003-.003.005a.016.016 0 0 1-.005.003l-.005.001H14.778l-.004-.004a.014.014 0 0 1-.003-.004v-.001-6.395-.005l.003-.005a.015.015 0 0 1 .004-.003h.005zM2.913 15.683h6.396l.005.004a.015.015 0 0 1 .003.004V22.091c0 .002-.002.003-.003.005a.016.016 0 0 1-.005.003h-.001l-.004.001H2.91c-.002 0-.003-.002-.005-.004a.014.014 0 0 1-.003-.004v-.001l-.001-.004v-6.391-.005l.004-.005a.015.015 0 0 1 .004-.003h.005z"
    />
  </svg>
);

EcosystemIcon.propTypes = {
  className: PropTypes.string,
};

EcosystemIcon.defaultProps = {
  className: '',
};

export default EcosystemIcon;
