import React from 'react';
import PropTypes from 'prop-types';

const GoodWriters = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 75 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59 55.4973C54.6525 47.7627 46.4288 42.5457 37.0003 42.5457C27.5712 42.5457 19.3475 47.7633 15 55.4979L15.0404 57.5457H58.9602L59 55.4973Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 55.7703C36.2452 49.0673 29.1429 44.5457 21 44.5457C12.8566 44.5457 5.75422 49.0679 2 55.7709L2.03463 57.5457H39.9654L40 55.7703Z"
      fill="#2D3942"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 27.0457C31 32.8445 26.2989 37.5457 20.4997 37.5457C14.7011 37.5457 10 32.8445 10 27.0457C10 21.2469 14.7011 16.5457 20.4997 16.5457C26.2989 16.5457 31 21.2469 31 27.0457Z"
      fill="#2D3942"
    />
    <path
      opacity="0.296015"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5177 36.5457C14.1307 36.5457 13.7408 36.4154 13.4173 36.1473C10.6098 33.8231 9 30.3775 9 26.6929C9 20.3029 13.8918 14.9683 20.1369 14.5491C21.105 14.4885 21.9332 15.2293 21.9961 16.2122C22.0597 17.1956 21.33 18.0447 20.3659 18.1095C15.9539 18.4056 12.497 22.1758 12.497 26.6929C12.497 29.2977 13.6353 31.7337 15.6205 33.3765C16.3706 33.9973 16.4848 35.121 15.8769 35.8857C15.5313 36.3203 15.0266 36.5457 14.5177 36.5457Z"
      fill="white"
    />
    <path
      opacity="0.296015"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.79661 57.5457C1.49574 57.5457 1.19066 57.4706 0.91016 57.3121C0.0464805 56.8246 -0.255597 55.733 0.235279 54.8749C3.36334 49.4011 8.64252 45.296 14.7188 43.6127C15.6742 43.3451 16.6674 43.9029 16.9335 44.8545C17.2002 45.8055 16.641 46.7911 15.6844 47.0563C10.5155 48.4888 6.02451 51.9825 3.36155 56.6405C3.0307 57.2203 2.42235 57.5457 1.79661 57.5457Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 55.7703C38.7548 49.0673 45.8571 44.5457 54 44.5457C62.1429 44.5457 69.2458 49.0679 73 55.7709L72.966 57.5457H35.0346L35 55.7703Z"
      fill="#2D3942"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43 27.0457C43 32.8445 47.7005 37.5457 53.4997 37.5457C59.2983 37.5457 64 32.8445 64 27.0457C64 21.2469 59.2983 16.5457 53.4997 16.5457C47.7005 16.5457 43 21.2469 43 27.0457Z"
      fill="#2D3942"
    />
    <path
      opacity="0.296015"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.9876 38.5457C51.8273 38.5457 49.7094 37.9581 47.8619 36.8455C47.0257 36.3424 46.7504 35.2483 47.2475 34.4009C47.7434 33.5535 48.8238 33.2746 49.6601 33.7783C50.9641 34.5626 52.46 34.9777 53.9876 34.9777C58.6701 34.9777 62.4788 31.1184 62.4788 26.3742C62.4788 23.7826 61.3415 21.354 59.3597 19.711C58.6073 19.0872 58.4964 17.9633 59.1114 17.2003C59.7276 16.438 60.8362 16.3244 61.5892 16.9494C64.392 19.2727 66 22.7081 66 26.3742C66 33.0855 60.6109 38.5457 53.9876 38.5457Z"
      fill="white"
    />
    <path
      opacity="0.296015"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.2034 57.5457C72.5777 57.5457 71.9693 57.2203 71.6385 56.6405C68.9761 51.9822 64.4845 48.4884 59.3156 47.0558C58.359 46.7906 57.7998 45.805 58.0665 44.8539C58.3326 43.9028 59.324 43.3462 60.2812 43.612C66.3581 45.2955 71.6373 49.4013 74.7647 54.8748C75.2556 55.7329 74.9535 56.8246 74.0898 57.3121C73.8093 57.4706 73.5043 57.5457 73.2034 57.5457Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59 55.4973C54.6525 47.7627 46.4288 42.5457 37.0003 42.5457C27.5712 42.5457 19.3475 47.7633 15 55.4979L15.0404 57.5457H58.9602L59 55.4973Z"
      fill="#2D3942"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.7702 54.8553C58.0341 49.9299 53.911 46.0875 49.0747 43.7079C49.1316 43.7079 49.1943 43.7079 49.2506 43.7079H49.2688C51.3051 43.7079 53.2183 42.8973 54.657 41.4231C56.1022 39.9429 56.8945 37.9749 56.8869 35.8833L56.8546 20.3457C56.8546 9.42865 48.1726 0.546254 37.5 0.545654C26.8279 0.545654 18.1453 9.42805 18.1453 20.3457V35.9079C18.1453 37.9965 18.9406 39.9585 20.3846 41.4321C21.8268 42.9045 23.7189 43.7205 25.7816 43.7079C25.8285 43.7079 25.8802 43.7073 25.9271 43.7073C21.0896 46.0875 16.9659 49.9299 14.2298 54.8565C13.7501 55.7205 14.0457 56.8197 14.8908 57.3105C15.1653 57.4701 15.4638 57.5457 15.7583 57.5457C16.3706 57.5457 16.9653 57.2181 17.2896 56.6349C21.4151 49.2069 29.1593 44.5929 37.5006 44.5929C45.8413 44.5929 53.5849 49.2069 57.7104 56.6337C58.1913 57.4977 59.2652 57.8001 60.1097 57.3093C60.9543 56.8179 61.2499 55.7193 60.7702 54.8553Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49 22.231C48.9982 21.1678 48.0302 20.4001 47.0411 20.6343C44.2588 21.2919 41.0968 21.188 37.9436 20.1641C34.0656 18.9048 30.9341 16.4869 28.971 13.5872C28.964 13.5952 28.4653 12.8595 28.2538 12.4729C27.7581 11.5664 26.6418 11.2683 25.7891 11.8312C24.9717 12.3715 24.7766 13.5392 25.2635 14.4144C25.5501 14.9307 25.8659 15.4329 26.2058 15.9215C26.5621 16.4322 26.6347 17.0991 26.4085 17.686C25.7909 19.2914 25.4821 21.0608 25.5782 22.9212C25.9011 29.1693 30.8087 34.2681 36.7693 34.5343C43.4742 34.833 49 29.2197 49 22.2532V22.231Z"
      fill="#2D3942"
    />
  </svg>
);

GoodWriters.propTypes = {
  className: PropTypes.string,
};

GoodWriters.defaultProps = {
  className: '',
};

export default GoodWriters;
