import React from 'react';
import PropTypes from 'prop-types';

const FullAccessIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} v viewBox="0 0 59 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9068 66H53.8369C56.6625 66 58.9532 63.6783 58.9532 60.8143V18.4448C58.9532 17.2126 58.4684 16.0299 57.6036 15.1521L44.0523 1.39873C43.1706 0.503854 41.9668 0 40.7105 0H12.9068C10.0812 0 7.79053 2.32172 7.79053 5.18571L7.79053 60.8143C7.79053 63.6783 10.0812 66 12.9068 66Z"
      fill="#868686"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="0" width="52" height="66">
      <path
        d="M12.9068 66H53.8369C56.6625 66 58.9532 63.6783 58.9532 60.8143V18.4448C58.9532 17.2126 58.4684 16.0299 57.6036 15.1521L44.0523 1.39873C43.1706 0.503854 41.9668 0 40.7105 0H12.9068C10.0812 0 7.79053 2.32172 7.79053 5.18571L7.79053 60.8143C7.79053 63.6783 10.0812 66 12.9068 66Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.95405 19.9199H39.4183V50.4356L7.8584 55.1561L7.95405 19.9199Z"
        fill="#636363"
      />
    </g>
    <path
      d="M15.7724 62.3837H50.9712C53.4011 62.3837 55.371 60.38 55.371 57.9084V21.6015C55.371 20.3722 54.8885 19.1919 54.0272 18.3147L42.7506 6.82944C41.8685 5.93096 40.6622 5.4248 39.403 5.4248H15.7724C13.3424 5.4248 11.3726 7.42848 11.3726 9.90015L11.3726 57.9084C11.3726 60.38 13.3424 62.3837 15.7724 62.3837Z"
      stroke="white"
      strokeWidth="1.8"
    />
    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="4" width="47" height="60">
      <path
        d="M15.7724 62.3837H50.9712C53.4011 62.3837 55.371 60.38 55.371 57.9084V21.6015C55.371 20.3722 54.8885 19.1919 54.0272 18.3147L42.7506 6.82944C41.8685 5.93096 40.6622 5.4248 39.403 5.4248H15.7724C13.3424 5.4248 11.3726 7.42848 11.3726 9.90015L11.3726 57.9084C11.3726 60.38 13.3424 62.3837 15.7724 62.3837Z"
        fill="white"
        stroke="white"
        strokeWidth="1.8"
      />
    </mask>
    <g mask="url(#mask1)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.61627 17.4434H39.2441C40.689 17.4434 41.8603 18.6746 41.8603 20.1934V47.6934C41.8603 49.2121 40.689 50.4434 39.2441 50.4434H2.61627C1.17134 50.4434 0 49.2121 0 47.6934V20.1934C0 18.6746 1.17134 17.4434 2.61627 17.4434Z"
      fill="#2C3841"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9685 26.887C17.631 26.3389 15.1627 27.015 13.4168 28.761C13.0107 29.167 12.6477 29.6391 12.3379 30.1645C12.2425 30.3268 12.2685 30.5333 12.4015 30.6663L14.4159 32.6807C15.489 31.0046 17.2866 28.7304 19.9685 26.887Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7515 40.0163L23.7779 42.0423C23.8572 42.122 23.9629 42.1633 24.0699 42.1633C24.1422 42.1633 24.2144 42.1447 24.2797 42.1059C24.8055 41.7957 25.278 41.4327 25.6832 41.027C27.4469 39.2633 28.1194 36.7756 27.5469 34.4258C25.6997 37.1188 23.4239 38.9325 21.7515 40.0163Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4783 38.4873C12.1115 38.4873 11 39.5988 11 40.9656V43.0308C11 43.2588 11.1846 43.4438 11.413 43.4438H13.4783C14.845 43.4438 15.9565 42.3323 15.9565 40.9656C15.9565 39.5988 14.845 38.4873 13.4783 38.4873Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9096 35.3236C13.941 35.2447 16.4647 29.168 22.8776 26.166L28.2579 31.5459C25.2472 37.9481 19.2007 40.5007 19.1222 40.5329C19.0714 40.5536 19.0185 40.5635 18.9656 40.5635C18.8582 40.5635 18.7525 40.5213 18.6736 40.4425L14.0008 35.7697C13.884 35.6528 13.848 35.4773 13.9096 35.3236ZM20.087 32.7045C20.087 33.6169 20.8268 34.3567 21.7392 34.3567C22.6516 34.3567 23.3914 33.6169 23.3914 32.7045C23.3914 31.7921 22.6516 31.0523 21.7392 31.0523C20.8268 31.0523 20.087 31.7921 20.087 32.7045Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6145 30.7343C29.296 29.0652 29.7693 27.1577 29.8928 24.9864C29.8999 24.8691 29.8561 24.7546 29.7735 24.6716C29.6904 24.5882 29.5694 24.5432 29.4591 24.5506C27.2779 24.6646 25.3638 25.1322 23.6914 25.8108L28.6145 30.7343Z"
      fill="white"
    />
  </svg>
);

FullAccessIcon.propTypes = {
  className: PropTypes.string,
};

FullAccessIcon.defaultProps = {
  className: '',
};

export default FullAccessIcon;
