import React from 'react';
import PropTypes from 'prop-types';

const ScrollHandlebarIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4883 0.999997C10.9654 0.999997 6.48828 5.47715 6.48828 11C6.48828 16.5228 10.9654 21 16.4883 21C22.0111 21 26.4883 16.5228 26.4883 11C26.4883 5.47715 22.0111 0.999998 16.4883 0.999997Z"
      fill="#FDFCFC"
      stroke="#BB2B22"
    />
    <circle cx="16.4883" cy="11" r="4" fill="#BB2B22" />
    <path d="M32.4883 11.2188L28.4883 7V16L32.4883 11.2188Z" fill="#BB2B22" />
    <path d="M0.488281 10.7812L4.48828 15L4.48828 6L0.488281 10.7812Z" fill="#BB2B22" />
  </svg>
);

ScrollHandlebarIcon.propTypes = {
  className: PropTypes.string,
};

ScrollHandlebarIcon.defaultProps = {
  className: '',
};

export default ScrollHandlebarIcon;
