import React from 'react';
import PropTypes from 'prop-types';

const Calendar = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} className={className} fill="none" viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 2.5H1.5C0.948 2.5 0.5 2.948 0.5 3.5V14.5C0.5 15.052 0.948 15.5 1.5 15.5H14.5C15.052 15.5 15.5 15.052 15.5 14.5V3.5C15.5 2.948 15.052 2.5 14.5 2.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M0.5 5.5H15.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.5 0.5V2.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.5 0.5V2.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5 7.5V13.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.5 7.5V13.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.5 10.5H13.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

Calendar.propTypes = {
  className: PropTypes.string,
};

Calendar.defaultProps = {
  className: '',
};

export default Calendar;
