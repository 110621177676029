import React from 'react';
import PropTypes from 'prop-types';

const Google = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} className={className} fill="none" viewBox="0 0 22 22">
    <path
      d="M4.87566 13.2951L4.10988 16.1539L1.31093 16.2131C0.474461 14.6616 0 12.8865 0 11.0002C0 9.17614 0.443609 7.45601 1.22994 5.94141H1.23054L3.72238 6.39825L4.81396 8.87514C4.5855 9.5412 4.46097 10.2562 4.46097 11.0002C4.46106 11.8077 4.60732 12.5813 4.87566 13.2951Z"
      fill="currentColor"
    />
    <path
      d="M21.8078 8.94531C21.9341 9.61073 22 10.2979 22 11.0003C22 11.7878 21.9172 12.556 21.7595 13.297C21.224 15.8185 19.8248 18.0203 17.8865 19.5784L17.8859 19.5778L14.7473 19.4177L14.3031 16.6447C15.5892 15.8904 16.5944 14.71 17.1238 13.297H11.2418V8.94531H17.2096H21.8078Z"
      fill="currentColor"
    />
    <path
      d="M17.8859 19.5776L17.8865 19.5782C16.0014 21.0934 13.6067 22 11 22C6.81091 22 3.16884 19.6586 1.31091 16.2129L4.87564 13.2949C5.80459 15.7741 8.19618 17.539 11 17.539C12.2051 17.539 13.3342 17.2132 14.303 16.6445L17.8859 19.5776Z"
      fill="currentColor"
    />
    <path
      d="M18.0213 2.53241L14.4578 5.44981C13.4551 4.82307 12.2699 4.46102 11 4.46102C8.13278 4.46102 5.69645 6.30682 4.81405 8.87494L1.23058 5.9412H1.22998C3.06071 2.41154 6.74871 0 11 0C13.669 0 16.1162 0.950727 18.0213 2.53241Z"
      fill="currentColor"
    />
  </svg>
);

Google.propTypes = {
  className: PropTypes.string,
};

Google.defaultProps = {
  className: '',
};

export default Google;
