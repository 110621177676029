import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import bgLeft from '../../../images/auth/bg-left.png';
import bgRight from '../../../images/auth/bg-right.png';
import Header from './Header';

const Wrapper = styled.div`
  position: relative;
  background: linear-gradient(169.07deg, #ffffff 17.52%, #f3f5f7 115.01%);

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: inline-block;
    width: 216px;
    height: 350px;
    background: url(${bgLeft});
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 94px;
    display: inline-block;
    width: 284px;
    height: 635px;
    background: url(${bgRight});
  }

  @media (max-width: 768px) {
    &::before,
    &::after {
      display: none;
    }
  }
`;

const MainContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
  z-index: 1;
`;

const AuthLayout = ({ user, children }) => (
  <Wrapper>
    <Header user={user} />

    <MainContainer>
      <main>{children}</main>
    </MainContainer>
  </Wrapper>
);

AuthLayout.propTypes = {
  user: PropTypes.shape({}),
  children: PropTypes.node,
};

AuthLayout.defaultProps = {
  user: null,
  children: null,
};

export default AuthLayout;
