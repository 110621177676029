// these sizes are arbitrary and you can set them to whatever you wish
import { css } from 'styled-components';

export const sizesResponsive = {
  giant: 1690,
  desktop: 1280,
  tablet: 1024,
  bigPhone: 736,
  phone: 480,
};

// iterate through the sizes and create a media template
export const media = Object.keys(sizesResponsive).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizesResponsive[label] / 16;
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});
