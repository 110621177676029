import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Icon from '../Icon';
import RoundButton from '../Button/RoundButton';

const DropDownButton = styled(RoundButton)`
  font-size: 1em;
  padding: 0 30px;
  ${p =>
    p.invert &&
    css`
      background-color: white;
      color: ${p.theme.brand};

      &:hover {
        background-color: white;
        box-shadow: 0 2px 2px 0 rgba(128, 128, 128, 0.25);
        color: ${p.theme.redButtonHoverColor};
      }
    `}
`;

const ArrowIcon = styled(Icon).attrs({ type: 'ArrowDown' })`
  font-size: 12px;
  margin-left: 10px;
  transition: transform 0.3s;
`;

const DropDownContainer = styled.div`
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 8px 10px;
  position: absolute;
  min-width: 100%;
  min-height: 50px;
  top: 55px;
  display: none;
  z-index: 1;
  cursor: pointer;

  &::before {
    content: '';
    width: 100%;
    height: 50px;
    background-color: transparent;
    position: absolute;
    top: -50px;
    left: 0;
  }
  
  @media screen and (max-width: 1024px) {
    display: ${p => (p.show ? 'block' : 'none')};
`;

const LinkItem = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 0.9em;
  text-transform: capitalize;
  color: black;
  text-decoration: none;
  padding: 5px 10px;
  display: block;
  text-align: center;
  margin: 5px 0;
  transition: background 0.2s, font-weight 0.2s;

  &:hover {
    background: #f5f5f5;
    font-weight: bold;
  }
`;

const TextItem = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 0.9em;
  line-height: 18px;
  text-transform: capitalize;
  color: black;
  padding: 5px 10px;
  display: block;
  text-align: center;
  margin: 5px 0;
  transition: background 0.2s, font-weight 0.2s;

  &:hover {
    background: #f5f5f5;
    font-weight: bold;
  }

  ${p =>
    p.active &&
    css`
      background: #f5f5f5;
      font-weight: bold;
    `}
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  font-size: 1rem;

  &:focus {
    outline: 0;
  }

  &,
  * {
    user-select: none;
  }

  ${p =>
    p.show &&
    css`
      ${ArrowIcon} {
        transform: rotate(-180deg);
      }

      ${DropDownContainer} {
        display: block;
      }
    `}
`;

function DropDown({ items, invert, label, children, className, onItemClick }) {
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);

  const clickedItselfOrItsChildren = event =>
    wrapperRef.current.contains(event.target) || wrapperRef.current === event.target;

  const anchorTagHasUrl = event =>
    /a/gi.test(event.target.tagName) && event.target.href && event.target.href.length > 0;

  const isSpanTag = event => /span/gi.test(event.target.tagName);

  function toggle(event) {
    if (clickedItselfOrItsChildren(event)) {
      setShow(!show);
      if (anchorTagHasUrl(event)) {
        event.target.click();
        return;
      }

      if (isSpanTag(event)) {
        onItemClick(event);
      }
    } else {
      setShow(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', toggle);

    return () => {
      document.removeEventListener('mousedown', toggle);
    };
  });

  return (
    <Wrapper show={show} ref={wrapperRef}>
      <DropDownButton invert={invert} onClick={toggle} className={className}>
        {label} <ArrowIcon />
      </DropDownButton>
      <DropDownContainer show={show}>
        {items &&
          !children &&
          items.map(item => (
            <LinkItem key={item.id} to={item.href}>
              {item.name}
            </LinkItem>
          ))}
        {children}
      </DropDownContainer>
    </Wrapper>
  );
}

DropDown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ),
  invert: PropTypes.bool,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  onItemClick: PropTypes.func,
};

DropDown.defaultProps = {
  invert: false,
  children: null,
  items: null,
  className: '',
  onItemClick: () => {},
};

export default DropDown;
export { LinkItem, TextItem };
