import React from 'react';
import PropTypes from 'prop-types';

const PrevArrowIcon = ({ className, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
    className={className}
    width="66"
    height="66"
    fill="none"
    viewBox="0 0 66 66"
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M29 52c11.598 0 21-9.402 21-21s-9.402-21-21-21S8 19.402 8 31s9.402 21 21 21z"
      clipRule="evenodd"
      filter="url(#a)"
    />
    <path stroke="#AC1B1B" strokeLinecap="square" strokeWidth="2" d="M31 35l-4-4 4-4" />
    <defs>
      <filter id="a" width="66" height="66" x="0" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx="4" dy="2" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

PrevArrowIcon.propTypes = {
  className: PropTypes.string,
};

PrevArrowIcon.defaultProps = {
  className: '',
};

export default PrevArrowIcon;
