import React from 'react';
import PropTypes from 'prop-types';

const Quote = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 84 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 45.1327C0 26.7788 10.678 9.62832 24.7119 0L36 9.02655C26.2373 15.3451 17.6949 27.3805 16.4746 37.0089C16.7797 37.0089 18.9153 36.4071 20.4407 36.4071C28.9831 36.4071 35.3898 43.0266 35.3898 51.7522C35.3898 60.7788 28.0678 68 18.9153 68C8.84746 68 0 59.8761 0 45.1327ZM48 45.1327C48 26.7788 58.678 9.62832 72.7119 0L84 9.02655C74.2373 15.3451 65.6949 27.3805 64.4746 37.0089C65.0847 37.0089 67.2203 36.4071 68.4407 36.4071C76.983 36.4071 83.3898 43.0266 83.3898 51.7522C83.3898 60.7788 76.0678 68 66.9153 68C56.8475 68 48 59.8761 48 45.1327Z"
      fill="#D22222"
    />
  </svg>
);

Quote.propTypes = {
  className: PropTypes.string,
};

Quote.defaultProps = {
  className: '',
};

export default Quote;
