import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import logo from '../../../images/logo.svg';
import UserDropdown from './UserDropdown';

const Wrapper = styled.header`
  display: flex;
  align-items: flex-start;
  padding: 50px 60px 40px 60px;
  position: relative;
  z-index: 10;

  @media (max-width: 524px) {
    padding: 30px 40px 40px 40px;
  }
`;

const Logo = styled.img`
  height: 46px;
  margin: 0;
  padding: 0;

  @media (max-width: 524px) {
    height: 36px;
  }
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

function Header({ user }) {
  return (
    <Wrapper>
      <Link to="/">
        <Logo src={logo} alt="DOL English" />
      </Link>
      <Right>{user && <UserDropdown user={user} />}</Right>
    </Wrapper>
  );
}

Header.propTypes = {
  user: PropTypes.shape({}),
};

Header.defaultProps = {
  user: null,
};

export default Header;
