import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import langs from '../../content/langs';
import DropDown, { TextItem } from '../DropDown';

const initialState = langs.find(item => /en/gi.test(item.value));

const LangDropDown = styled(DropDown)`
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.8em;

  &:hover {
    background-color: transparent;
  }
`;

const LangItem = styled(TextItem)`
  white-space: nowrap;
`;

const LangMobileItem = styled.span`
  color: ${p => (p.active ? 'black' : '#939393')};
  font-weight: 700;

  &:not(:first-child)::before {
    content: '|';
    margin: 0 1em;
    color: #939393;
  }
`;

const Wrapper = styled.div``;

function Lang({ onSelect, mobile, ...rest }) {
  const [selectedLang, setLang] = React.useState(initialState);

  const handleClick = e => {
    const clickedLang = langs.find(lang => lang.id === Number(e.target.id));
    setLang(clickedLang);
    onSelect(e);
  };

  return (
    <>
      {mobile ? (
        <Wrapper {...rest}>
          {langs.map(lang => (
            <LangMobileItem
              onClick={handleClick}
              key={lang.id}
              data-value={lang.value}
              id={lang.id}
              active={lang.id === selectedLang.id}
            >
              {lang.name}
            </LangMobileItem>
          ))}
        </Wrapper>
      ) : (
        <LangDropDown label={selectedLang.value} onItemClick={handleClick}>
          {langs.map(lang => (
            <LangItem key={lang.id} data-value={lang.value} id={lang.id} active={lang.id === selectedLang.id}>
              {lang.name}
            </LangItem>
          ))}
        </LangDropDown>
      )}
    </>
  );
}

export default Lang;

Lang.propTypes = {
  onSelect: PropTypes.func,
  mobile: PropTypes.bool,
};

Lang.defaultProps = {
  onSelect: () => {},
  mobile: false,
};
