import React from 'react';
import PropTypes from 'prop-types';

const MenuDropdownIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.96916 3.35645L0.544806 0.83895L5.39351 0.83895L2.96916 3.35645Z" fill="currentColor" />
  </svg>
);

MenuDropdownIcon.propTypes = {
  className: PropTypes.string,
};

MenuDropdownIcon.defaultProps = {
  className: '',
};

export default MenuDropdownIcon;
