import React from 'react';
import PropTypes from 'prop-types';

const MobileToolbarIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0.820312H15C16.654 0.820312 18 2.16631 18 3.82031V21.8203C18 23.4743 16.654 24.8203 15 24.8203H3C1.346 24.8203 0 23.4743 0 21.8203V3.82031C0 2.16631 1.346 0.820312 3 0.820312ZM15 21.8203C15.552 21.8203 16 21.3723 16 20.8203V4.82031C16 4.26831 15.552 3.82031 15 3.82031H3C2.448 3.82031 2 4.26831 2 4.82031V20.8203C2 21.3723 2.448 21.8203 3 21.8203H15Z"
      fill="currentColor"
    />
    <rect x="4" y="15.8203" width="10" height="4" fill="currentColor" />
  </svg>
);

MobileToolbarIcon.propTypes = {
  className: PropTypes.string,
};

MobileToolbarIcon.defaultProps = {
  className: '',
};

export default MobileToolbarIcon;
