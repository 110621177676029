import React from 'react';
import PropTypes from 'prop-types';

const PlayCircleIcon = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.976562 23C0.976562 10.318 11.2936 0 23.9766 0C36.6596 0 46.9766 10.318 46.9766 23C46.9766 35.682 36.6596 46 23.9766 46C11.2936 46 0.976562 35.682 0.976562 23ZM20.5316 31.832L32.5316 23.832C32.8096 23.646 32.9766 23.334 32.9766 23C32.9766 22.666 32.8096 22.354 32.5306 22.168L20.5306 14.168C20.2246 13.962 19.8286 13.944 19.5046 14.118C19.1796 14.292 18.9766 14.631 18.9766 15V31C18.9766 31.369 19.1796 31.708 19.5046 31.882C19.6526 31.961 19.8146 32 19.9766 32C20.1706 32 20.3646 31.943 20.5316 31.832Z"
      fill="currentColor"
    />
  </svg>
);

PlayCircleIcon.propTypes = {
  className: PropTypes.string,
};

PlayCircleIcon.defaultProps = {
  className: '',
};

export default PlayCircleIcon;
