import React from 'react';
import PropTypes from 'prop-types';

const ClockIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.35 18.9244V21.6774H10.65V18.9127V18.6636L10.4009 18.6627L5.04472 18.6431L5.05513 16.1431L14.4084 16.1774H14.4093H14.4116C15.4339 16.1846 16.0875 15.9259 16.4782 15.6127L16.3219 15.4176L16.4782 15.6127C16.6677 15.4608 16.8144 15.2923 16.9119 15.0906C17.0094 14.8888 17.05 14.6696 17.05 14.4274C17.05 13.4162 16.238 12.6774 15.2 12.6774H8.8C6.29116 12.6774 4.25 10.7414 4.25 8.42737C4.25 7.26546 4.79335 6.12443 5.75949 5.34997C6.72859 4.57314 8.07595 4.15986 9.61591 4.17735V4.17737H9.61875H10.4H10.65V3.92737V1.17737H13.35V3.92737V4.17737H13.6H17.35V6.67737H9.59069H9.58273C8.57291 6.66572 7.92132 6.92398 7.52805 7.23911L7.528 7.23915C7.33706 7.39223 7.1891 7.56241 7.09057 7.76444C6.99186 7.96682 6.95 8.18629 6.95 8.42737C6.95 9.43871 7.76199 10.1774 8.8 10.1774H15.2C17.7088 10.1774 19.75 12.1135 19.75 14.4274C19.75 15.586 19.2114 16.7316 18.2468 17.5048C17.28 18.2796 15.9354 18.692 14.3961 18.6774L14.3947 18.6774L13.6009 18.6744L13.35 18.6735V18.9244Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);

ClockIcon.propTypes = {
  className: PropTypes.string,
};

ClockIcon.defaultProps = {
  className: '',
};

export default ClockIcon;
