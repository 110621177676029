import React from 'react';
import PropTypes from 'prop-types';

const EssayCorrectionIcon = ({ className, ...rest }) => (
  <svg className={className} {...rest} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22">
    <path stroke="currentColor" strokeLinecap="square" strokeWidth="1.8" d="M8.287 3.165H.904v17.93h17.931v-7.383" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.397 14.767l-4.22 1.055 1.056-4.22L18.835 0 22 3.164 10.397 14.767z"
      clipRule="evenodd"
    />
  </svg>
);

EssayCorrectionIcon.propTypes = {
  className: PropTypes.string,
};

EssayCorrectionIcon.defaultProps = {
  className: '',
};

export default EssayCorrectionIcon;
