import React from 'react';
import PropTypes from 'prop-types';

const Medal = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.4287 57.1429H57.143V36.1905H41.4287V57.1429ZM38.5716 57.1429H34.762V51.4286C34.762 48.8029 32.6259 46.6667 30.0001 46.6667C27.3744 46.6667 25.2382 48.8029 25.2382 51.4286V57.1429H21.4287V11.5648L30.0001 3.40143L38.5716 11.5648V57.1429ZM2.85693 57.1429H18.5712V36.1905H2.85693V57.1429ZM2.85712 33.3334H18.5714V26.6667H2.85712V33.3334ZM41.4286 33.3334H57.1429V26.6667H41.4286V33.3334ZM58.5714 23.8095H41.4286V10.9524C41.4286 10.9467 41.4271 10.9419 41.4267 10.9357C41.4262 10.8762 41.4171 10.8171 41.409 10.7581C41.4043 10.7238 41.4033 10.6881 41.3967 10.6548C41.3857 10.6038 41.3662 10.5548 41.35 10.5052C41.3376 10.4667 41.3286 10.4267 41.3129 10.39C41.2957 10.35 41.271 10.3129 41.25 10.2743C41.2267 10.2314 41.2062 10.1871 41.179 10.1471C41.1586 10.1171 41.1319 10.09 41.1086 10.0614C41.0724 10.0167 41.0381 9.97095 40.9967 9.93095C40.9924 9.92714 40.9895 9.92191 40.9852 9.9181L30.9852 0.394286C30.4329 -0.131429 29.5671 -0.131429 29.0148 0.394286L19.0148 9.9181C19.0105 9.92191 19.0076 9.92714 19.0033 9.93095C18.9619 9.97095 18.9276 10.0167 18.8914 10.0614C18.8681 10.09 18.8414 10.1171 18.821 10.1471C18.7933 10.1876 18.7729 10.2324 18.749 10.2757C18.7286 10.3138 18.7038 10.3505 18.6871 10.39C18.6714 10.4271 18.6624 10.4676 18.6495 10.5062C18.6333 10.5557 18.6143 10.6043 18.6033 10.6548C18.5967 10.6881 18.5957 10.7238 18.591 10.7581C18.5829 10.8171 18.5738 10.8762 18.5733 10.9357C18.5729 10.9419 18.5714 10.9467 18.5714 10.9524V23.8095H1.42857C0.639524 23.8095 0 24.449 0 25.2381V34.7619V58.5714C0 59.3605 0.639524 60 1.42857 60H58.5714C59.3605 60 60 59.3605 60 58.5714V34.7619V25.2381C60 24.449 59.3605 23.8095 58.5714 23.8095Z"
      fill="#444B54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.9999 14.7619C29.2108 14.7619 28.5713 14.1224 28.5713 13.3334C28.5713 12.5443 29.2108 11.9048 29.9999 11.9048C30.7889 11.9048 31.4284 12.5443 31.4284 13.3334C31.4284 14.1224 30.7889 14.7619 29.9999 14.7619Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.0002 16.1904C28.4245 16.1904 27.1431 14.9089 27.1431 13.3332C27.1431 11.7575 28.4245 10.4761 30.0002 10.4761C31.5759 10.4761 32.8574 11.7575 32.8574 13.3332C32.8574 14.9089 31.5759 16.1904 30.0002 16.1904Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.4284 41.4287H28.5713V22.8573C28.5713 22.0682 29.2108 21.4287 29.9999 21.4287C30.7889 21.4287 31.4284 22.0682 31.4284 22.8573V41.4287Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.4288 42.8571H28.5716C27.7826 42.8571 27.1431 42.2176 27.1431 41.4286V22.8571C27.1431 21.2814 28.4245 20 30.0002 20C31.5759 20 32.8574 21.2814 32.8574 22.8571V41.4286C32.8574 42.2176 32.2178 42.8571 31.4288 42.8571Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09508 43.3332H6.6665V41.1904C6.6665 40.7956 6.9865 40.4761 7.38079 40.4761C7.77508 40.4761 8.09508 40.7956 8.09508 41.1904V43.3332Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09542 44.7621H6.66685C5.87781 44.7621 5.23828 44.1226 5.23828 43.3336V41.1907C5.23828 40.0093 6.19971 39.0479 7.38114 39.0479C8.56257 39.0479 9.524 40.0093 9.524 41.1907V43.3336C9.524 44.1226 8.88447 44.7621 8.09542 44.7621Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09508 52.8571H6.6665V50.7143C6.6665 50.3195 6.9865 50 7.38079 50C7.77508 50 8.09508 50.3195 8.09508 50.7143V52.8571Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09518 54.2856H6.66661C5.87756 54.2856 5.23804 53.6461 5.23804 52.857V50.7141C5.23804 49.5327 6.19947 48.5713 7.38089 48.5713C8.56232 48.5713 9.52375 49.5327 9.52375 50.7141V52.857C9.52375 53.6461 8.88423 54.2856 8.09518 54.2856Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2381 43.3332H13.8096V41.1904C13.8096 40.7956 14.1296 40.4761 14.5239 40.4761C14.9181 40.4761 15.2381 40.7956 15.2381 41.1904V43.3332Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.238 44.7621H13.8094C13.0204 44.7621 12.3809 44.1226 12.3809 43.3336V41.1907C12.3809 40.0093 13.3423 39.0479 14.5237 39.0479C15.7051 39.0479 16.6666 40.0093 16.6666 41.1907V43.3336C16.6666 44.1226 16.0271 44.7621 15.238 44.7621Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2381 52.8571H13.8096V50.7143C13.8096 50.3195 14.1296 50 14.5239 50C14.9181 50 15.2381 50.3195 15.2381 50.7143V52.8571Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2381 54.2856H13.8096C13.0205 54.2856 12.381 53.6461 12.381 52.857V50.7141C12.381 49.5327 13.3424 48.5713 14.5238 48.5713C15.7053 48.5713 16.6667 49.5327 16.6667 50.7141V52.857C16.6667 53.6461 16.0272 54.2856 15.2381 54.2856Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.3334 43.3332H51.9048V41.1904C51.9048 40.7956 52.2248 40.4761 52.6191 40.4761C53.0134 40.4761 53.3334 40.7956 53.3334 41.1904V43.3332Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.3332 44.7621H51.9046C51.1156 44.7621 50.4761 44.1226 50.4761 43.3336V41.1907C50.4761 40.0093 51.4375 39.0479 52.6189 39.0479C53.8004 39.0479 54.7618 40.0093 54.7618 41.1907V43.3336C54.7618 44.1226 54.1223 44.7621 53.3332 44.7621Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.3334 52.8571H51.9048V50.7143C51.9048 50.3195 52.2248 50 52.6191 50C53.0134 50 53.3334 50.3195 53.3334 50.7143V52.8571Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.3333 54.2856H51.9048C51.1157 54.2856 50.4762 53.6461 50.4762 52.857V50.7141C50.4762 49.5327 51.4376 48.5713 52.6191 48.5713C53.8005 48.5713 54.7619 49.5327 54.7619 50.7141V52.857C54.7619 53.6461 54.1224 54.2856 53.3333 54.2856Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.6669 43.3332H45.2383V41.1904C45.2383 40.7956 45.5583 40.4761 45.9526 40.4761C46.3469 40.4761 46.6669 40.7956 46.6669 41.1904V43.3332Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.6667 44.7621H45.2381C44.4491 44.7621 43.8096 44.1226 43.8096 43.3336V41.1907C43.8096 40.0093 44.771 39.0479 45.9524 39.0479C47.1339 39.0479 48.0953 40.0093 48.0953 41.1907V43.3336C48.0953 44.1226 47.4558 44.7621 46.6667 44.7621Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.6669 52.8571H45.2383V50.7143C45.2383 50.3195 45.5583 50 45.9526 50C46.3469 50 46.6669 50.3195 46.6669 50.7143V52.8571Z"
      fill="#D3D8DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.6667 54.2856H45.2381C44.4491 54.2856 43.8096 53.6461 43.8096 52.857V50.7141C43.8096 49.5327 44.771 48.5713 45.9524 48.5713C47.1339 48.5713 48.0953 49.5327 48.0953 50.7141V52.857C48.0953 53.6461 47.4558 54.2856 46.6667 54.2856Z"
      fill="#D3D8DD"
    />
  </svg>
);

Medal.propTypes = {
  className: PropTypes.string,
};

Medal.defaultProps = {
  className: '',
};

export default Medal;
