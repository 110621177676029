import React from 'react';
import PropTypes from 'prop-types';

const LoadMoreIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.10994 14.783C3.45339 14.783 0.0546875 11.6329 0.0546875 7.73383C0.0616642 7.37955 0.0845557 7.03507 0.128169 6.72944C0.259689 5.86802 0.726854 5.66625 1.11419 5.70053C1.31117 5.71798 1.48802 5.80953 1.60414 5.95777C1.71459 6.09878 1.78303 6.29425 1.73887 6.58245C1.72963 6.62236 1.72621 6.66687 1.72051 6.70493C1.65653 7.03527 1.61027 7.37934 1.61027 7.73382C1.61027 10.7649 4.07951 13.2212 7.10994 13.2212C10.1339 13.2212 12.5974 10.7649 12.5974 7.73382C12.5974 4.70918 10.1346 2.2464 7.10994 2.2464C6.42765 2.2464 5.78085 2.36828 5.18689 2.58937C4.21075 3.00249 3.57503 1.68224 4.56833 1.15625C5.34201 0.790587 6.34217 0.690235 7.10994 0.684698C11.0021 0.684698 14.1529 3.83475 14.1529 7.73382C14.086 11.8199 10.589 14.7251 7.10994 14.783Z"
      fill="black"
    />
  </svg>
);

LoadMoreIcon.propTypes = {
  className: PropTypes.string,
};

LoadMoreIcon.defaultProps = {
  className: '',
};

export default LoadMoreIcon;
