import React from 'react';
import PropTypes from 'prop-types';

const MathBasedIcon = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} className={className} fill="none" viewBox="0 0 23 23">
    <path
      fill="currentColor"
      d="M22.042 21.083H1.917V.958A.959.959 0 0 0 0 .958v21.084c0 .529.43.958.958.958h21.084a.959.959 0 0 0 0-1.917z"
    />
    <path
      fill="currentColor"
      d="M13.416 8.625V11.5h-2.875a.959.959 0 0 0-.677.28l-5.75 5.75a.958.958 0 1 0 1.355 1.356l5.47-5.47h3.436c.529 0 .958-.429.958-.958V9.022l4.792-4.792v3.437a.959.959 0 0 0 1.916 0v-5.75c0-.063-.006-.126-.018-.188-.006-.029-.017-.055-.025-.082-.01-.033-.017-.065-.03-.097-.014-.032-.032-.061-.049-.092-.013-.024-.023-.05-.04-.073a.959.959 0 0 0-.265-.266c-.024-.016-.05-.027-.075-.041-.03-.016-.058-.034-.09-.047-.032-.013-.065-.021-.098-.03-.027-.008-.053-.019-.08-.025a1.022 1.022 0 0 0-.188-.018h-5.75a.959.959 0 0 0 0 1.917h3.437l-5.073 5.072a.959.959 0 0 0-.28.678z"
    />
  </svg>
);

MathBasedIcon.propTypes = {
  className: PropTypes.string,
};

MathBasedIcon.defaultProps = {
  className: '',
};

export default MathBasedIcon;
