import React from 'react';
import PropTypes from 'prop-types';

const Apple = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.13307 5.19017C6.62525 5.12529 7.5984 5.96869 8.57156 5.96869C9.54471 5.96869 10.453 5.12529 12.01 5.19017C13.3725 5.19017 14.8646 5.70918 15.9027 6.94185C14.4105 7.6555 13.3076 9.21255 13.3076 11.0291C13.3076 12.9754 14.6051 14.5973 16.2919 15.1812C15.5783 17.2573 14.4105 19.2036 12.7886 20.2417C10.7125 21.3446 9.47984 19.3983 8.57156 19.3983C7.5984 19.3983 6.43061 21.3446 4.35455 20.2417C1.88922 18.6846 0.397045 15.1164 0.0726592 12.132C-0.511235 7.00673 2.53799 5.19017 5.13307 5.19017ZM8.31205 4.86578C8.24717 1.81656 9.8691 0.389263 12.6588 0C12.1398 3.30873 10.8423 4.34677 8.31205 4.86578Z"
      fill="black"
    />
  </svg>
);

Apple.propTypes = {
  className: PropTypes.string,
};

Apple.defaultProps = {
  className: '',
};

export default Apple;
