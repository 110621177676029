import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import Header, { menuHeightDesktop } from '../../Header';
import theme from '../../../style/theme';
import Footer from './Footer';
import * as Auth from '../../../utils/auth';

import Version from '../../Version';

const MainContainer = styled.div`
  margin: 0 auto;
  overflow: hidden;
`;

const Main = styled.main`
  padding-top: ${menuHeightDesktop}px;
`;

function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <Header siteTitle={data.site.siteMetadata.title} user={Auth.isLogged() && Auth.getUserInfo()} />
          <MainContainer>
            <Main>{children}</Main>
          </MainContainer>
          <Footer />
          <Version />
        </ThemeProvider>
      )}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
