import React from 'react';
import PropTypes from 'prop-types';

const RoadIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 10V15C9 15.6 8.6 16 8 16H6C5.4 16 5 15.6 5 15V10H9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2L16 5L13 8H1C0.4 8 0 7.6 0 7V3C0 2.4 0.4 2 1 2H13Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M6 0H8C8.6 0 9 0.4 9 1V2H5V1C5 0.4 5.4 0 6 0Z" fill="currentColor" />
  </svg>
);

RoadIcon.propTypes = {
  className: PropTypes.string,
};

RoadIcon.defaultProps = {
  className: '',
};

export default RoadIcon;
