import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import { lighten } from 'polished';
import Icon from '../Icon';

const findMyName = menu => {
  if (!menu.contextNames || typeof window === 'undefined') return menu.name;
  const context = menu.contextNames.find($context => $context.path === window.location.pathname);

  return (context && context.name) || menu.name;
};

const DropdownIcon = styled(Icon).attrs({ type: 'MenuDropdownIcon' })`
  font-size: 7px;
  margin-left: 9px;
  color: #c4c4c4;
`;
const SubMenuIcon = styled(Icon).attrs({ className: 'sub-menu-icon' })`
  font-size: 25px;
  margin: 0 10px ${props => (props.isColumns ? '10px' : 0)} 0;
  color: #969ca0;
`;
const SubmenuContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: ${props => (props.isColumns ? 'flex-start' : 'center')};
  flex: 0 0 ${props => (props.columns ? `calc(100% / ${props.columns})` : '33%')};

  &:hover {
    .sub-menu-name:not(.grand-sub-menu-name) {
      font-weight: bold;
      color: ${props => props.theme.brand};

      a {
        text-decoration: none;

        &:hover {
          font-weight: bold;
          color: ${props => props.theme.brand};

          &:visited {
            color: ${props => props.theme.brand};
          }
        }

        &:visited {
          color: initial;
        }
      }
    }
    .sub-menu-icon {
      color: ${props => props.theme.brand};
    }
  }

  ${props =>
    props.active &&
    css`
      ${SubMenuIcon} {
        color: ${props.theme.brand};
      }
    `}

  ${props =>
    props.oneline &&
    css`
      flex-direction: row;
    `}
`;

const ComingSoon = styled.span`
  background: #f1f1f1;
  border-radius: 4px;
  font-size: 0.6em;
  text-align: center;
  color: black;
  mix-blend-mode: normal;
  text-transform: none;
  padding: 0.1em 1em;
  margin-left: 1em;
  font-weight: 300;
`;

const SubMenuName = styled.p.attrs({ className: 'sub-menu-name' })`
  margin-bottom: 0;
  font-style: normal;
  display: block;
  font-weight: ${props => props.fontWeight || 300};
  line-height: 18px;
  font-size: 14px;
  text-transform: capitalize;
  color: ${props => (props.mobileVersion ? '#808080' : '#666666')};
  
  ${props =>
    props.isComingSoon &&
    css`
      color: ${p => lighten(0.3, p.mobileVersion ? '#808080' : '#666666')};

      a {
        display: flex;
        align-items: center;
      }
    `}

  a {
    text-decoration: none;
    color: inherit; 
  }

    &:hover {
      font-weight: bold;
      color: ${props => props.theme.brand};

      &:visited {
        color: ${props => props.theme.brand};
      }
    }

    &:visited {
      color: initial;
    }
  }
`;

const SubMenuUnderline = styled.div`
  width: 30px;
  border: 1px solid ${props => props.theme.brand};
  margin: 10px 0 20px 0;
`;

const GrandChildrenMenus = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  ${props =>
    props.mobileVersion &&
    props.hasIcon &&
    css`
      margin-left: 35px;
    `}
`;

const GrandMenuName = styled(SubMenuName).attrs({ className: 'grand-sub-menu-name' })`
  margin: 10px 0;
`;

const GoToDOLOnlineLinkWrapper = styled.span`
  display: inline-block;
  background-color: #eeeeee;
  padding: 5px;
  font-weight: bold;
  font-size: 13px;
  margin-left: 5px;
  line-height: 25px;
`;

const GoToDOLOnlineLinkIcon = styled(Icon).attrs({ type: 'ExternalLink' })`
  margin-left: 5px;
  font-size: 11px;
  color: #5c5c5c;
`;

const GoToDOLOnlineLink = styled.a`
  text-transform: none;
  text-align: left;
  font-weight: 300;
`;

const GoToDOLOnline = ({ mobileVersion }) => (
  <GoToDOLOnlineLink href="https://dolonline.com" target="_blank">
    Go to
    <GoToDOLOnlineLinkWrapper>
      Dolonline.com
      <GoToDOLOnlineLinkIcon />
    </GoToDOLOnlineLinkWrapper>
    {!mobileVersion && <p>for more information</p>}
    {mobileVersion && <span>&nbsp;for more information</span>}
  </GoToDOLOnlineLink>
);

GoToDOLOnline.propTypes = {
  mobileVersion: PropTypes.bool,
};

GoToDOLOnline.defaultProps = {
  mobileVersion: PropTypes.false,
};

const NavigableSubMenu = ({
  isComingSoon,
  isExternal,
  iconType,
  href,
  name,
  isReactComponent,
  mobileVersion,
  ...props
}) => (
  <SubmenuContainer oneline {...props}>
    {iconType && <SubMenuIcon type={iconType} />}
    <SubMenuName isComingSoon={isComingSoon}>
      {!isReactComponent && (
        <>
          {isExternal ? (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {name}
            </a>
          ) : (
            <Link to={href}>
              {name} {isComingSoon && <ComingSoon>Coming soon</ComingSoon>}
            </Link>
          )}
        </>
      )}
      {isReactComponent && <GoToDOLOnline mobileVersion={mobileVersion} />}
    </SubMenuName>
  </SubmenuContainer>
);

NavigableSubMenu.propTypes = {
  iconType: PropTypes.string,
  href: PropTypes.string,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  mobileVersion: PropTypes.bool,
  isReactComponent: PropTypes.bool,
  className: PropTypes.string,
  isExternal: PropTypes.bool,
  isComingSoon: PropTypes.bool,
};

NavigableSubMenu.defaultProps = {
  iconType: '',
  href: '',
  active: false,
  mobileVersion: false,
  isReactComponent: false,
  className: '',
  isExternal: false,
  isComingSoon: false,
};

const SubMenuMobile = styled.div`
  display: flex;
  align-items: center;
`;

const UnnavigableSubMenu = ({ iconType, name, children, mobileVersion, ...props }) => (
  <SubmenuContainer {...props} isColumns mobileVersion={mobileVersion}>
    {/* Desktop version here */}
    {!mobileVersion && (
      <>
        {iconType && <SubMenuIcon type={iconType} isColumns />}
        <SubMenuName fontWeight="bold">{name}</SubMenuName>
      </>
    )}

    {/* Mobile version here */}
    {mobileVersion && (
      <SubMenuMobile>
        {iconType && <SubMenuIcon type={iconType} />}
        <SubMenuName fontWeight="bold" mobileVersion>
          {name}
        </SubMenuName>
      </SubMenuMobile>
    )}

    {children && children.length > 0 && (
      <>
        {!mobileVersion && <SubMenuUnderline />}
        {children.map(childMenu => (
          <GrandChildrenMenus
            key={childMenu.id.toString()}
            mobileVersion={mobileVersion}
            hasIcon={iconType !== null && iconType.length > 0}
          >
            <GrandMenuName>
              {childMenu.isExternal ? (
                <a href={childMenu.href} target="_blank" rel="noopener noreferrer">
                  {childMenu.name}
                </a>
              ) : (
                <Link to={childMenu.href}>{childMenu.name}</Link>
              )}
            </GrandMenuName>
          </GrandChildrenMenus>
        ))}
      </>
    )}
  </SubmenuContainer>
);

UnnavigableSubMenu.propTypes = {
  iconType: PropTypes.string,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  mobileVersion: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.number,
  className: PropTypes.string,
};

UnnavigableSubMenu.defaultProps = {
  iconType: '',
  active: false,
  mobileVersion: false,
  children: [],
  columns: 3,
  className: '',
};

const isNotNavigableMenu = menu => menu.href === null;
const isNavigableMenu = menu => menu.href !== null;

const isActive = path => {
  if (typeof window === 'undefined') return false;
  if (!window.location) return false;
  return window.location.pathname.indexOf(path) > -1;
};

const hasChildren = menu => typeof menu.children !== 'undefined' && menu.children.length > 0;

export {
  findMyName,
  DropdownIcon,
  NavigableSubMenu,
  UnnavigableSubMenu,
  SubMenuName,
  isNotNavigableMenu,
  isNavigableMenu,
  isActive,
  hasChildren,
};
