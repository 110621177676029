import React from 'react';
import PropTypes from 'prop-types';

const SurveyIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4H23" stroke="currentColor" strokeWidth="2" />
    <rect x="2" y="1" width="6" height="6" stroke="currentColor" strokeWidth="2" />
    <path d="M12 16H23" stroke="currentColor" strokeWidth="2" />
    <path d="M2 13L8 19" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
    <path d="M8 13L2 19" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
  </svg>
);

SurveyIcon.propTypes = {
  className: PropTypes.string,
};

SurveyIcon.defaultProps = {
  className: '',
};

export default SurveyIcon;
