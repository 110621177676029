import React from 'react';
import PropTypes from 'prop-types';

const ConsultationWithTeacherIcon = ({ className, ...rest }) => (
  <svg className={className} {...rest} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M7.264 11.636c-.237.71-.355 1.537-.355 2.364M21.09 14c0-3.9-3.19-7.09-7.09-7.09-.827 0-1.655.117-2.364.354"
      opacity=".5"
    />
    <path stroke="currentColor" strokeLinecap="square" strokeWidth="2" d="M12.345 12.345L8.091 8.091" />
    <path
      fill="#607187"
      fillRule="evenodd"
      d="M14 16.363a2.364 2.364 0 1 0 0-4.727 2.364 2.364 0 0 0 0 4.727z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M14 27c7.18 0 13-5.82 13-13S21.18 1 14 1 1 6.82 1 14s5.82 13 13 13z"
      clipRule="evenodd"
    />
  </svg>
);

ConsultationWithTeacherIcon.propTypes = {
  className: PropTypes.string,
};

ConsultationWithTeacherIcon.defaultProps = {
  className: '',
};

export default ConsultationWithTeacherIcon;
