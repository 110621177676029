import React from 'react';
import PropTypes from 'prop-types';

const Star = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} className={className} fill="none" viewBox="0 0 9 9">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.6785 3.12939L6.02341 2.72453L4.83742 0.201732C4.76726 0.0767338 4.63932 0 4.50108 0C4.36283 0 4.23489 0.0767338 4.16474 0.201732L2.97649 2.72453L0.321403 3.12939C0.180158 3.15078 0.0627309 3.25445 0.0184865 3.39682C-0.0257578 3.53919 0.0108519 3.69557 0.112926 3.80022L2.03459 5.76511L1.58127 8.5401C1.55717 8.68771 1.61501 8.83687 1.73047 8.92488C1.84594 9.01289 1.99901 9.02449 2.12533 8.9548L4.49995 7.64501L6.87457 8.9548C7.00089 9.02449 7.15396 9.01289 7.26943 8.92488C7.3849 8.83687 7.44274 8.68771 7.41864 8.5401L6.96531 5.76511L8.88698 3.80022C8.98902 3.69568 9.02571 3.53944 8.98164 3.39712C8.93757 3.25479 8.82037 3.15103 8.67925 3.12939H8.6785Z"
      fill="currentColor"
    />
  </svg>
);

Star.propTypes = {
  className: PropTypes.string,
};

Star.defaultProps = {
  className: '',
};

export default Star;
