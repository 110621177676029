import React from 'react';
import PropTypes from 'prop-types';

const ChatBubbleSquare = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.155 2.18334V2.21048H18.28C18.5546 2.21048 18.7856 2.34274 18.9521 2.57263C19.1202 2.80479 19.2217 3.13714 19.2217 3.52374V13.5645C19.2217 13.9385 19.125 14.3009 18.956 14.5657C18.7877 14.8293 18.5562 14.9863 18.28 14.9863H16.5733H15.72H15.595V15.1113V16.2782V18.949C14.8194 18.1849 14.2153 17.0995 13.9651 15.9001C13.9651 15.9 13.9651 15.8999 13.965 15.8998L13.7945 15.0593L13.7742 14.9591H13.672H13.032H3.13333C2.86081 14.9591 2.57299 14.8015 2.34894 14.5355C2.12617 14.271 1.97833 13.9123 1.97833 13.5373V3.49661C1.97833 2.71876 2.5892 2.18334 3.13333 2.18334H18.155ZM16.6983 16.3217H18.28C18.8839 16.3217 19.3986 15.9958 19.7577 15.4926C20.1163 14.9902 20.325 14.3058 20.325 13.5645V3.52374C20.325 2.05103 19.4885 0.875 18.28 0.875H3.13333C1.93897 0.875 0.875 2.06075 0.875 3.52374V13.5645C0.875 14.2996 1.14149 14.9856 1.55398 15.489C1.96567 15.9915 2.53176 16.3217 3.13333 16.3217H12.9105C13.4064 18.4812 14.7977 20.3737 16.524 21.1149L16.6983 21.1897V21V16.3217Z"
      fill="currentColor"
      stroke="#6B747A"
      strokeWidth="0.25"
    />
    <path
      d="M17.0002 9.53326H4.2002V8.46659H17.0002V9.53326ZM17.0002 11.1333H4.2002V12.1999H17.0002V11.1333ZM6.33353 5.79992V6.86659H17.0002V5.79992H6.33353Z"
      fill="currentColor"
    />
  </svg>
);

ChatBubbleSquare.propTypes = {
  className: PropTypes.string,
};

ChatBubbleSquare.defaultProps = {
  className: '',
};

export default ChatBubbleSquare;
