import React from 'react';
import PropTypes from 'prop-types';

const BookWithBookMark = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.07695 20.9672H16.0922C16.3758 20.9672 16.6035 20.7394 16.6035 20.4609V17.7188H16.9688C17.2523 17.7188 17.4629 17.4783 17.4629 17.1998V1.13906C17.4629 0.860625 17.2523 0.632812 16.9688 0.632812H3.07695C1.85234 0.632812 0.876953 1.59047 0.876953 2.7675V18.8283C0.876953 20.0095 1.85234 20.9672 3.07695 20.9672ZM7.9668 1.64531H13.5527V7.64859L11.0734 5.83031C10.8887 5.69531 10.6309 5.69531 10.4461 5.83031L7.9668 7.64859V1.64531ZM1.9082 2.7675C1.9082 2.14734 2.41953 1.64531 3.07695 1.64531H6.93555V8.66109C6.93555 8.85094 7.05156 9.02813 7.22344 9.1125C7.29648 9.15047 7.37813 9.16735 7.45547 9.16735C7.56289 9.16735 7.67461 9.13359 7.76484 9.06609L10.7555 6.86813L13.7461 9.06609C13.9008 9.18 14.1199 9.20109 14.2918 9.1125C14.468 9.02813 14.584 8.85094 14.584 8.66109V1.64531H16.4316V16.7063H3.07695C2.63867 16.7063 2.25195 16.8159 1.9082 17.0269V2.7675ZM3.07695 17.7188H15.5723V19.9547H3.07695C2.42383 19.9547 1.89102 19.4569 1.89102 18.8367C1.88672 18.2166 2.41953 17.7188 3.07695 17.7188Z"
      fill="currentColor"
    />
  </svg>
);

BookWithBookMark.propTypes = {
  className: PropTypes.string,
};

BookWithBookMark.defaultProps = {
  className: '',
};

export default BookWithBookMark;
