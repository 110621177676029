import React from 'react';
import PropTypes from 'prop-types';

const PersonalProgramIcon = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} className={className} fill="none" viewBox="0 0 24 17">
    <path fill="currentColor" d="M23.13 2.896H6.807v1.88H23.13v-1.88zM17.263 12.224H1.015v1.88h16.248v-1.88z" />
    <path
      fill="currentColor"
      d="M19.897 17a3.852 3.852 0 0 1-3.836-3.836 3.852 3.852 0 0 1 3.836-3.837c2.106 0 3.799 1.73 3.799 3.837 0 2.106-1.693 3.836-3.8 3.836zm0-5.792a1.966 1.966 0 0 0-1.956 1.956c0 1.09.865 1.956 1.956 1.956a1.966 1.966 0 0 0 1.956-1.956c0-1.091-.903-1.956-1.956-1.956zM3.836 7.673A3.852 3.852 0 0 1 0 3.836 3.852 3.852 0 0 1 3.836 0a3.852 3.852 0 0 1 3.837 3.836 3.852 3.852 0 0 1-3.837 3.837zm0-5.755a1.966 1.966 0 0 0-1.955 1.956c0 1.09.865 1.956 1.955 1.956a1.966 1.966 0 0 0 1.956-1.956c0-1.09-.903-1.956-1.956-1.956z"
    />
  </svg>
);

PersonalProgramIcon.propTypes = {
  className: PropTypes.string,
};

PersonalProgramIcon.defaultProps = {
  className: '',
};

export default PersonalProgramIcon;
