import React from 'react';
import PropTypes from 'prop-types';

const AppleCircleIcon = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={className}
    width="47"
    height="47"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5 46C35.9264 46 46 35.9264 46 23.5C46 11.0736 35.9264 1 23.5 1C11.0736 1 1 11.0736 1 23.5C1 35.9264 11.0736 46 23.5 46Z"
      stroke="#D0021B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5 46C35.9264 46 46 35.9264 46 23.5C46 11.0736 35.9264 1 23.5 1C11.0736 1 1 11.0736 1 23.5C1 35.9264 11.0736 46 23.5 46Z"
      stroke="#D0021B"
    />
    <path
      d="M32.3925 27.996C32.0454 27.846 31.7171 27.6677 31.4358 27.4051C30.6385 26.6641 30.1038 25.773 29.9725 24.6756C29.9162 24.1597 29.9162 23.6345 30.0382 23.1186C30.1976 22.4339 30.5259 21.8336 31.023 21.327C31.342 21.0081 31.6702 20.6986 31.9892 20.3797C32.1861 20.1827 32.2236 19.8919 32.0548 19.6762C31.6984 19.226 31.3044 18.8039 30.8355 18.4569C30.2539 18.016 29.5973 17.7159 28.8845 17.547C28.378 17.4345 27.8715 17.3688 27.3462 17.4157C26.4739 17.5001 25.6391 17.744 24.8137 18.0254C24.1571 18.2411 23.5193 18.3068 22.8439 18.1098C22.0654 17.8753 21.2775 17.6596 20.4615 17.5658C19.6267 17.472 18.82 17.5283 18.0321 17.8284C17.0473 18.2036 16.2875 18.8414 15.6778 19.695C15.2276 20.3234 14.8899 21.0081 14.6648 21.7491C14.4022 22.6027 14.2802 23.4844 14.2802 23.9627C14.2427 25.229 14.3647 26.0732 14.5429 26.908C14.6836 27.5458 14.8805 28.1649 15.115 28.7745C15.6684 30.2284 16.3907 31.5791 17.3755 32.7797C17.9008 33.4175 18.5011 33.9709 19.2609 34.3086C19.7486 34.5243 20.2551 34.6087 20.771 34.5243C21.315 34.4399 21.8403 34.2429 22.3562 34.046C22.6845 33.924 23.0128 33.8115 23.3598 33.7552C23.9132 33.6614 24.4385 33.7646 24.9637 33.924C25.5171 34.0928 26.0518 34.2898 26.6052 34.4399C27.44 34.665 28.2091 34.5618 28.9407 34.1116C29.8506 33.5488 30.5259 32.7515 31.1168 31.8792C31.7734 30.8944 32.3268 29.8532 32.6833 28.7183C32.7958 28.3994 32.6926 28.118 32.3925 27.996Z"
      fill="#BB2B22"
    />
    <path
      d="M23.4644 17.0218C23.5582 17.0124 23.6614 17.0031 23.7552 16.9937C24.4211 16.9374 25.0496 16.7967 25.6123 16.4028C26.0344 16.1026 26.4002 15.7556 26.7285 15.3616C27.1319 14.8739 27.4695 14.3392 27.6759 13.7389C27.8259 13.3074 27.8728 12.8478 27.9197 12.3976C27.9479 12.1256 27.9479 11.863 27.9666 11.591C27.9948 11.1689 27.704 10.8312 27.235 10.9438C26.766 11.0563 26.3064 11.197 25.8656 11.4034C25.1996 11.7129 24.5899 12.1068 24.121 12.6884C23.2768 13.7295 22.911 14.9489 22.8359 16.2714C22.8359 16.3465 22.8359 16.4121 22.8359 16.4872C22.8359 16.8624 23.0892 17.0781 23.4644 17.0218Z"
      fill="#BB2B22"
    />
  </svg>
);

AppleCircleIcon.propTypes = {
  className: PropTypes.string,
};

AppleCircleIcon.defaultProps = {
  className: '',
};

export default AppleCircleIcon;
