import React from 'react';
import PropTypes from 'prop-types';

const FacebookSquare = ({ className, ...rest }) => (
  <svg className={className} {...rest} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22">
    <rect width="22" height="22" fill="#4B67AD" rx="2" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.562 11h-3.438V8.25c0-.759.617-.688 1.376-.688h1.375V4.126h-2.75a4.125 4.125 0 0 0-4.126 4.125V11H8.25v3.438H11V22h4.125v-7.563h2.063L18.562 11z"
      clipRule="evenodd"
    />
  </svg>
);

FacebookSquare.propTypes = {
  className: PropTypes.string,
};

FacebookSquare.defaultProps = {
  className: '',
};

export default FacebookSquare;
