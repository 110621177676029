import React from 'react';
import PropTypes from 'prop-types';

const MethodIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 10C7.98528 10 10 7.98528 10 5.5C10 3.01472 7.98528 1 5.5 1C3.01472 1 1 3.01472 1 5.5C1 7.98528 3.01472 10 5.5 10Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <rect x="11" y="11" width="8" height="8" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
  </svg>
);

MethodIcon.propTypes = {
  className: PropTypes.string,
};

MethodIcon.defaultProps = {
  className: '',
};

export default MethodIcon;
