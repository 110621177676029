import React from 'react';
import PropTypes from 'prop-types';

const MarkerIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7 4.3L11.7 0.3C11.3 -0.1 10.7 -0.1 10.3 0.3L2.3 8.3C1.9 8.7 1.9 9.3 2.3 9.7L3.3 10.7L0 14L3 15L5.3 12.7L6.3 13.7C6.6 14 7.1 14.3 7.7 13.7L15.7 5.7C16.1 5.3 16.1 4.7 15.7 4.3Z"
      fill="currentColor"
    />
  </svg>
);

MarkerIcon.propTypes = {
  className: PropTypes.string,
};

MarkerIcon.defaultProps = {
  className: '',
};

export default MarkerIcon;
