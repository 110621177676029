import React from 'react';
import PropTypes from 'prop-types';

const Writing = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.836 7.63574L5.6 9.59974L4 19.9997L14.4 18.3997L16.364 13.1637"
      stroke="currentColor"
      strokeWidth="1.6"
    />
    <path d="M4 19.9998L9.2688 14.731" stroke="currentColor" strokeWidth="1.6" />
    <rect
      x="9.6001"
      y="6.3999"
      width="3.39437"
      height="11.3135"
      transform="rotate(-45 9.6001 6.3999)"
      stroke="currentColor"
      strokeWidth="1.59998"
      strokeLinecap="square"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5313 14.731C12.1561 14.1061 12.1561 13.0931 11.5313 12.4682C10.9065 11.8434 9.8934 11.8434 9.26857 12.4682C8.64373 13.0931 8.64373 14.1061 9.26857 14.731C9.8934 15.3558 10.9065 15.3558 11.5313 14.731Z"
      stroke="currentColor"
      strokeWidth="1.59998"
      strokeLinecap="square"
    />
  </svg>
);

Writing.propTypes = {
  className: PropTypes.string,
};

Writing.defaultProps = {
  className: '',
};

export default Writing;
