import React from 'react';
import PropTypes from 'prop-types';

const StarIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.357 5.91107L12.0468 5.14633L9.67483 0.38105C9.53452 0.144942 9.27865 0 9.00215 0C8.72566 0 8.46979 0.144942 8.32948 0.38105L5.95298 5.14633L0.642806 5.91107C0.360316 5.95148 0.125462 6.1473 0.036973 6.41622C-0.0515157 6.68513 0.0217038 6.98052 0.225851 7.17819L4.06919 10.8896L3.16253 16.1313C3.11433 16.4101 3.23001 16.6919 3.46095 16.8581C3.69188 17.0243 3.99802 17.0463 4.25067 16.9146L8.9999 14.4406L13.7491 16.9146C14.0018 17.0463 14.3079 17.0243 14.5389 16.8581C14.7698 16.6919 14.8855 16.4101 14.8373 16.1313L13.9306 10.8896L17.774 7.17819C17.978 6.98072 18.0514 6.68561 17.9633 6.41678C17.8751 6.14795 17.6407 5.95194 17.3585 5.91107H17.357Z"
      fill="currentColor"
    />
  </svg>
);

StarIcon.propTypes = {
  className: PropTypes.string,
};

StarIcon.defaultProps = {
  className: '',
};

export default StarIcon;
