import React from 'react';
import PropTypes from 'prop-types';

const Tones = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 57 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.0002 34.7998C56.0002 38.1133 53.3137 40.7998 50.0002 40.7998C46.6867 40.7998 44.0002 38.1133 44.0002 34.7998C44.0002 31.4863 46.6867 28.7998 50.0002 28.7998C53.3137 28.7998 56.0002 31.4863 56.0002 34.7998Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.0002 39.7998C31.0002 43.1133 28.3137 45.7998 25.0002 45.7998C21.6867 45.7998 19.0002 43.1133 19.0002 39.7998C19.0002 36.4863 21.6867 33.7998 25.0002 33.7998C28.3137 33.7998 31.0002 36.4863 31.0002 39.7998Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0002 24.7998C13.0002 28.1133 10.3137 30.7998 7.00024 30.7998C3.68674 30.7998 1.00024 28.1133 1.00024 24.7998C1.00024 21.4863 3.68674 18.7998 7.00024 18.7998C10.3137 18.7998 13.0002 21.4863 13.0002 24.7998Z"
      fill="white"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="33">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000244141 0.799805H21.0001V32.7997H0.000244141V0.799805Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50037 29.7521C5.01937 29.7521 3.00037 27.701 3.00037 25.1807C3.00037 22.6603 5.01937 20.6092 7.50037 20.6092C9.98137 20.6092 12.0004 22.6603 12.0004 25.1807C12.0004 27.701 9.98137 29.7521 7.50037 29.7521ZM13.4538 0.799706C12.6438 0.804786 12.0003 1.50066 12.0003 2.32352V18.1549C12.0003 18.5526 11.5773 18.7862 11.2393 18.5866C9.87977 17.7846 8.25477 17.4006 6.52877 17.6251C3.12227 18.0675 0.398267 20.8962 0.0422671 24.3659C-0.446233 29.1228 3.40427 33.1238 8.05977 32.7789C12.0073 32.4863 15.0003 29.0146 15.0003 24.9937V4.1084C16.7378 4.73469 17.9878 6.41545 18.0003 8.38929C18.0043 9.02167 18.3498 9.61799 18.9338 9.83691C19.9993 10.2362 21.0003 9.44631 21.0003 8.41875C21.0003 4.20187 17.6103 0.773802 13.4538 0.799706Z"
        fill="#444B54"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.5003 39.6781C47.0192 39.6781 45.0002 37.6284 45.0002 35.1096C45.0002 32.5908 47.0192 30.5411 49.5003 30.5411C51.9813 30.5411 54.0003 32.5908 54.0003 35.1096C54.0003 37.6284 51.9813 39.6781 49.5003 39.6781ZM32.0002 20.5615V16.4285C32.0002 14.7407 33.1837 13.2753 34.8138 12.9443L49.8138 9.89865C50.8473 9.6885 51.9053 9.95753 52.7193 10.6352C53.5333 11.3129 54.0003 12.3144 54.0003 13.3829V16.095L32.0002 20.5615ZM24.5003 44.7542C22.0192 44.7542 20.0002 42.7045 20.0002 40.1857C20.0002 37.6669 22.0192 35.6172 24.5003 35.6172C26.9813 35.6172 29.0003 37.6669 29.0003 40.1857C29.0003 42.7045 26.9813 44.7542 24.5003 44.7542ZM57.0002 13.3829C57.0002 11.092 55.8447 8.96768 53.8762 7.7418C52.4472 6.85247 50.7162 6.60932 49.0727 6.94282L34.2257 9.95752C31.1872 10.5748 29.0002 13.2829 29.0002 16.4285V33.1645C29.0002 33.5619 28.5772 33.7954 28.2392 33.5959C26.8797 32.7944 25.2547 32.4107 23.5287 32.635C20.1222 33.0771 17.3981 35.904 17.0421 39.3715C16.5536 44.1253 20.4042 48.1237 25.0597 47.7791C29.0072 47.4867 32.0002 44.0172 32.0002 39.9989V23.6676L54.0002 19.2011V28.0883C54.0002 28.4858 53.5772 28.7193 53.2392 28.5198C51.8797 27.7183 50.2547 27.3345 48.5287 27.5589C45.1222 28.001 42.3982 30.8279 42.0422 34.2954C41.5537 39.0492 45.4042 43.0476 50.0597 42.703C54.0072 42.4106 57.0002 38.9411 57.0002 34.9228V13.3829Z"
      fill="#444B54"
    />
  </svg>
);

Tones.propTypes = {
  className: PropTypes.string,
};

Tones.defaultProps = {
  className: '',
};

export default Tones;
