import React from 'react';
import PropTypes from 'prop-types';

const CalendarAddIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2H15C15.6 2 16 2.4 16 3V15C16 15.6 15.6 16 15 16H1C0.4 16 0 15.6 0 15V3C0 2.4 0.4 2 1 2H3V1C3 0.4 3.4 0 4 0H5C5.6 0 6 0.4 6 1V2H10V1C10 0.4 10.4 0 11 0H12C12.6 0 13 0.4 13 1V2ZM2 14H14V6H2V14Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M11 11H9V13H7V11H5V9H7V7H9V9H11V11Z" fill="currentColor" />
  </svg>
);

CalendarAddIcon.propTypes = {
  className: PropTypes.string,
};

CalendarAddIcon.defaultProps = {
  className: '',
};

export default CalendarAddIcon;
