import React from 'react';
import PropTypes from 'prop-types';

const LoudSpeakerWithThreeSticks = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 29 35" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M19.6541 6.26479C19.5316 6.18626 19.3914 6.13953 19.2463 6.12883C19.1011 6.11814 18.9556 6.14381 18.8229 6.20354L9.36414 10.4998H4.30664C4.07458 10.4998 3.85202 10.592 3.68792 10.7561C3.52383 10.9202 3.43164 11.1427 3.43164 11.3748V16.6248C3.43164 16.8569 3.52383 17.0794 3.68792 17.2435C3.85202 17.4076 4.07458 17.4998 4.30664 17.4998H9.36414L18.8229 21.796C18.9356 21.8474 19.0578 21.8742 19.1816 21.8748C19.3493 21.8744 19.5133 21.8258 19.6541 21.7348C19.7774 21.6557 19.8788 21.547 19.9491 21.4186C20.0194 21.2902 20.0564 21.1462 20.0566 20.9998V6.99979C20.0564 6.85339 20.0194 6.70939 19.9491 6.58097C19.8788 6.45256 19.7774 6.34385 19.6541 6.26479ZM8.68164 15.7498H5.18164V12.2498H8.68164V15.7498ZM18.3066 19.6435L10.4316 16.0648V11.9348L18.3066 8.35604V19.6435Z"
        fill="currentColor"
      />
      <path
        d="M22.6816 9.625C22.871 9.625 23.0552 9.56359 23.2066 9.45L26.7066 6.825C26.7986 6.75606 26.876 6.66968 26.9346 6.57081C26.9931 6.47193 27.0316 6.3625 27.0479 6.24874C27.0641 6.13499 27.0578 6.01915 27.0293 5.90784C27.0008 5.79653 26.9506 5.69193 26.8816 5.6C26.8127 5.50807 26.7263 5.43063 26.6275 5.37208C26.5286 5.31354 26.4191 5.27504 26.3054 5.25879C26.1916 5.24254 26.0758 5.24886 25.9645 5.27738C25.8532 5.30589 25.7486 5.35606 25.6566 5.425L22.1566 8.05C21.971 8.18924 21.8483 8.39652 21.8154 8.62626C21.7826 8.85599 21.8424 9.08935 21.9816 9.275C22.0631 9.38367 22.1688 9.47187 22.2903 9.53262C22.4118 9.59337 22.5458 9.625 22.6816 9.625Z"
        fill="currentColor"
      />
      <path
        d="M23.2066 18.55C23.1147 18.4811 23.0101 18.4309 22.8988 18.4024C22.7875 18.3739 22.6716 18.3675 22.5579 18.3838C22.4441 18.4 22.3347 18.4385 22.2358 18.4971C22.137 18.5556 22.0506 18.6331 21.9816 18.725C21.9127 18.8169 21.8625 18.9215 21.834 19.0328C21.8055 19.1442 21.7992 19.26 21.8154 19.3737C21.8317 19.4875 21.8702 19.5969 21.9287 19.6958C21.9873 19.7947 22.0647 19.8811 22.1566 19.95L25.6566 22.575C25.8081 22.6886 25.9923 22.75 26.1816 22.75C26.3175 22.75 26.4515 22.7184 26.573 22.6576C26.6945 22.5969 26.8001 22.5087 26.8816 22.4C27.0209 22.2143 27.0807 21.981 27.0478 21.7513C27.015 21.5215 26.8923 21.3142 26.7066 21.175L23.2066 18.55Z"
        fill="currentColor"
      />
      <path
        d="M22.6816 14.875H26.1816C26.4137 14.875 26.6363 14.7828 26.8004 14.6187C26.9645 14.4546 27.0566 14.2321 27.0566 14C27.0566 13.7679 26.9645 13.5454 26.8004 13.3813C26.6363 13.2172 26.4137 13.125 26.1816 13.125H22.6816C22.4496 13.125 22.227 13.2172 22.0629 13.3813C21.8988 13.5454 21.8066 13.7679 21.8066 14C21.8066 14.2321 21.8988 14.4546 22.0629 14.6187C22.227 14.7828 22.4496 14.875 22.6816 14.875Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="28" height="35" fill="white" transform="translate(0.806641)" />
      </clipPath>
    </defs>
  </svg>
);

LoudSpeakerWithThreeSticks.propTypes = {
  className: PropTypes.string,
};

LoudSpeakerWithThreeSticks.defaultProps = {
  className: '',
};

export default LoudSpeakerWithThreeSticks;
