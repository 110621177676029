import React from 'react';
import PropTypes from 'prop-types';

const EditIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.3 2.7002H1V18.0002H16.3V11.7002" stroke="currentColor" strokeWidth="1.8" strokeLinecap="square" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.1 12.6L5.5 13.5L6.4 9.9L16.3 0L19 2.7L9.1 12.6Z"
      fill="currentColor"
    />
  </svg>
);

EditIcon.propTypes = {
  className: PropTypes.string,
};

EditIcon.defaultProps = {
  className: '',
};

export default EditIcon;
