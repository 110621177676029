import React from 'react';
import PropTypes from 'prop-types';

const Plus = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.13952 4.006V5.608H5.50352V9.244H3.91952V5.608H0.283523V4.006H3.91952V0.37H5.50352V4.006H9.13952Z"
      fill="currentColor"
    />
  </svg>
);

Plus.propTypes = {
  className: PropTypes.string,
};

Plus.defaultProps = {
  className: '',
};

export default Plus;
