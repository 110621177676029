import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.21273 0.78418V1.56666H10.1807L3.02368 8.72365L3.57693 9.27689L10.7339 2.1199V5.08784H11.5164V0.78418H7.21273ZM0.300781 2.08832V11.9998H10.2123V6.39198H9.42977V11.2173H1.08327V2.8708H5.90859V2.08832H0.300781Z"
      fill="currentColor"
    />
  </svg>
);

ExternalLink.propTypes = {
  className: PropTypes.string,
};

ExternalLink.defaultProps = {
  className: '',
};

export default ExternalLink;
