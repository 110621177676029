import React from 'react';
import PropTypes from 'prop-types';

const Leaf = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 46 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5516 45.0566C6.5516 45.0566 -0.831055 13.5456 38.4243 3.30595C38.4243 3.30595 42.4479 1.76978 43.0001 1.05664L33.9836 13.971C26.2319 25.7909 19.3177 32.2821 10.8913 40.7141L6.5516 45.0566Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00006 44.5532L42.9933 1.05738C42.9933 0.953064 43.3066 11.8379 39.6748 22.8061C39.0733 24.6215 34.1965 23.6525 33.3602 25.4227C32.7662 26.6794 36.2757 30.6984 35.5467 31.89C30.2781 40.4997 21.2977 46.945 6.00006 44.5532Z"
      fill="#D3D8DD"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="56">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.10352e-05 0.258545H44.5608V55.0567H6.10352e-05V0.258545Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.5133 1.98464C44.5372 1.66799 44.4678 1.35347 44.3143 1.07794C44.2216 0.87396 44.1131 0.773571 44.0056 0.674785L44.0035 0.676921C43.9315 0.611241 43.8547 0.5493 43.769 0.496436C43.022 0.0329404 42.0436 0.267358 41.5841 1.02027C35.48 11.0201 28.4859 17.7445 21.7222 24.2468C16.5646 29.2059 11.5829 33.9978 7.28881 39.9336C7.22475 37.2012 7.47886 33.5365 8.71186 29.5941C12.0869 18.8082 20.846 11.1216 34.7478 6.74775C35.5853 6.48396 36.0523 5.5858 35.7907 4.74105C35.5297 3.89682 34.6382 3.42479 33.8017 3.68857C23.4745 6.93838 10.2043 13.9298 5.63492 28.7841C3.51516 35.6767 4.06945 41.6829 4.46281 44.1899C2.87404 46.8134 1.41498 49.6691 0.120041 52.8447C-0.214018 53.6627 0.173511 54.5993 0.98457 54.9358C1.18204 55.0175 1.38692 55.057 1.5881 55.057C2.21281 55.057 2.80522 54.6821 3.05722 54.0638C4.14145 51.4056 5.35963 48.9861 6.67628 46.7295C8.6748 47.0126 10.604 47.1615 12.4569 47.1615C23.0372 47.1615 31.2256 42.5501 36.8517 33.3998C37.3139 32.6479 37.0841 31.6601 36.3387 31.1939C35.5927 30.7277 34.6139 30.9595 34.1517 31.7113C28.5007 40.9033 19.8888 44.9396 8.53557 43.7366C12.9694 37.0917 18.335 31.9292 23.9139 26.5659C29.6951 21.0077 35.6399 15.2829 41.1288 7.50066C40.818 11.3731 40.0832 16.7967 38.3616 22.2572L32.7763 24.5742C31.9647 24.9106 31.5777 25.8466 31.9112 26.6647C32.1637 27.2836 32.7562 27.6579 33.3809 27.6579C33.582 27.6579 33.7864 27.6189 33.9844 27.5372L40.2484 24.9383C40.6751 24.761 41.0049 24.4054 41.1505 23.9638C43.0511 18.2198 43.8669 12.5174 44.2169 8.7443C44.571 4.93221 44.6145 3.00988 44.5133 1.98464Z"
        fill="#444B54"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4348 55.0566H15.5653C14.7008 55.0566 14.0001 54.3851 14.0001 53.5566C14.0001 52.7281 14.7008 52.0566 15.5653 52.0566H36.4348C37.2994 52.0566 38.0001 52.7281 38.0001 53.5566C38.0001 54.3851 37.2994 55.0566 36.4348 55.0566Z"
      fill="#444B54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.5001 55.0566C44.1051 55.0566 43.7201 54.8956 43.4401 54.6137C43.1601 54.3318 43.0001 53.9442 43.0001 53.5466C43.0001 53.1489 43.1601 52.7608 43.4401 52.4795C43.9951 51.9157 45.0001 51.9157 45.5601 52.4795C45.6301 52.5499 45.6951 52.6254 45.7451 52.706C45.8001 52.7915 45.8451 52.8771 45.8851 52.9677C45.9201 53.0583 45.9501 53.154 45.9701 53.2496C45.9901 53.3498 46.0001 53.4459 46.0001 53.5466C46.0001 53.9442 45.8401 54.3318 45.5601 54.6137C45.2801 54.8956 44.8951 55.0566 44.5001 55.0566Z"
      fill="#444B54"
    />
  </svg>
);

Leaf.propTypes = {
  className: PropTypes.string,
};

Leaf.defaultProps = {
  className: '',
};

export default Leaf;
