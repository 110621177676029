/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import * as Auth from '../../../utils/auth';
import { useOnClickOutside } from '../../../utils/onClickOutside';
import { hideAt } from '../../../utils/makeResponsive';
import Avatar from '../../Avatar';

const Mobile = hideAt({ min: '524px' });
const TabletAndUp = hideAt({ max: '523px' });

const Wrapper = styled.div`
  display: inline-block;
`;

const DropdownContainer = styled.div`
  z-index: 2;
  position: absolute;
  top: 50px;
  right: 136px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(27, 39, 51, 0.1);

  @media (max-width: 524px) {
    top: 30px;
    right: 92px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  padding: 14px 14px 20px 14px;
  border-bottom: 1px solid #f6f6f6;
`;

const Trigger = styled.div`
  cursor: pointer;
`;

const Info = styled.div`
  margin-left: 14px;
`;

const UserName = styled.div`
  font-size: 0.875rem;
`;

const UserEmail = styled.div`
  font-size: 0.75rem;
  color: #919bb0;
`;

const Menu = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  list-style: none;
`;

const Item = styled.li`
  margin: 0;
  padding: 10px 15px;
  cursor: pointer;

  a {
    color: inherit;
    text-decoration: none;
  }

  ${props =>
    props.danger &&
    css`
      color: #d22222;
    `}

  &:hover {
    background: #f6f6f6;
  }
`;

export function Dropdown({ user }) {
  if (!user) {
    return null;
  }

  return (
    <DropdownContainer>
      <UserInfo>
        <Avatar url={user.picture} />
        <Info>
          <UserName>{user.name}</UserName>
          <UserEmail>{user.email}</UserEmail>
        </Info>
      </UserInfo>

      <Menu>
        <Item danger onClick={Auth.logout}>
          Sign out
        </Item>
      </Menu>
    </DropdownContainer>
  );
}

Dropdown.propTypes = {
  user: PropTypes.shape({}),
};

Dropdown.defaultProps = {
  user: null,
};

function UserDropdown({ user, avatarSize, ...rest }) {
  const ref = useRef();
  const [visible, setVisible] = useState(false);

  useOnClickOutside(ref, () => setVisible(false));

  if (!user) {
    return null;
  }

  return (
    <Wrapper ref={ref} {...rest}>
      <Trigger onClick={() => setVisible(!visible)}>
        <TabletAndUp>
          <Avatar url={user.picture} size={avatarSize} />
        </TabletAndUp>
        <Mobile>
          <Avatar url={user.picture} />
        </Mobile>
      </Trigger>
      {visible && <Dropdown user={user} />}
    </Wrapper>
  );
}

UserDropdown.propTypes = {
  user: PropTypes.shape({}),
  avatarSize: PropTypes.string,
};

UserDropdown.defaultProps = {
  user: null,
  avatarSize: 'large',
};

export default UserDropdown;
