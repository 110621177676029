import React from 'react';
import PropTypes from 'prop-types';

const TeacherIcon = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} className={className} fill="none" viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M19 22h4v-4.323a1 1 0 0 0-.629-.928l-3.742-1.5a1 1 0 0 1-.629-.926v-.878A3.982 3.982 0 0 0 20 10V8a4 4 0 0 0-6-3.465"
    />
    <path
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M14.371 17.749l-3.742-1.5a1 1 0 0 1-.629-.926v-.451C13.27 14.435 14 13 14 13s-2-2-2-4a4 4 0 0 0-8 0c0 2-2 4-2 4s.73 1.435 4 1.872v.451a1 1 0 0 1-.629.928l-3.742 1.5a1 1 0 0 0-.629.926V22h14v-3.323a1 1 0 0 0-.629-.928z"
      clipRule="evenodd"
    />
  </svg>
);

TeacherIcon.propTypes = {
  className: PropTypes.string,
};

TeacherIcon.defaultProps = {
  className: '',
};

export default TeacherIcon;
