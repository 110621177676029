import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { DropdownIcon, findMyName, NavigableSubMenu, UnnavigableSubMenu, isNavigableMenu, isActive } from './utils';
import LogoImage from './LogoImage';
import menus from '../../content/menus';
import Lang from './Lang';
import UserDropdown from '../Layout/AuthLayout/UserDropdown';

export const menuHeightDesktop = 50;
const logoImageWidth = '114px';

const pricingWidth = '150px';

const MenuBackground = styled.div`
  width: 100vw;
  background-color: ${props => props.theme.bgHeader};
`;

const rootMenuIconDistance = '6%';

const RootMenus = styled.div`
  margin-left: ${rootMenuIconDistance};
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const Login = styled(Link).attrs({ to: '/login' })`
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 1.5em;
`;

const Actions = styled.div`
  color: white;
  font-size: 0.8em;
`;

const MenuWrapper = styled.div`
  margin: 0 auto;
  height: ${menuHeightDesktop}px;
  display: flex;
  position: relative;
  justify-content: space-between;
  max-width: 1440px;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.white};
  text-decoration: none;
`;

const LogoAnchor = styled(StyledLink).attrs({ to: '/', target: '_blank' })`
  padding-left: 120px;
`;

const Logo = styled(LogoImage)`
  width: 114px;
  height: 50px;
  display: inline-block;
  cursor: pointer;
`;

const activeNavItem = css`
  opacity: 1;
  border-bottom: 5px solid ${props => props.theme.brand};
`;

const pricingNavItem = css`
  opacity: 1;
  align-self: center;
  border-left: 1px solid #97979640;
  flex-basis: ${pricingWidth};
  margin: 0;
  height: 100%;
  background-color: ${props => props.theme.brand};
  color: ${props => props.theme.white};
  font-weight: bold;
`;

const Menu = styled.div`
  padding: 14px;
  opacity: 0.7;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.white};
  margin: 0 15px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  ${p =>
    p.active &&
    css`
      border-bottom: 2px solid ${p.theme.brand};
    `}

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    opacity: 1;

    ${props =>
      (!props.navigable || props.hoverable) &&
      css`
        .sub-menus-spot {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
        }
      `}
  }

  ${props => props.active && activeNavItem}

  ${props => props.pricing && pricingNavItem}
`;

const SubMenuSpot = styled.div.attrs({ className: 'sub-menus-spot' })`
  width: 100vw;
  position: fixed;
  left: 0;
  display: none;
  background-color: white;
  top: ${menuHeightDesktop}px;
  cursor: auto;
  box-shadow: 1px 5px 20px 0 #80808040;
`;

const SubMenus = styled.div`
  max-width: 1440px;
  flex: 1;
  padding: 20px 0 20px calc(${logoImageWidth} + 120px + 6% + 15px);
`;

const SubMenusWrapper = styled.div`
  max-width: 850px;
  display: flex;
  flex: 1;
  flex-flow: row wrap;
`;

const sortMenuByOrder = (menu1, menu2) => menu1.order - menu2.order;

const Pricing = styled(Link)`
  text-decoration: none;
  width: ${pricingWidth};
`;

const DesktopHeader = ({ user }) => (
  <MenuBackground>
    <MenuWrapper>
      <LogoAnchor>
        <Logo />
      </LogoAnchor>
      <RootMenus>
        {menus &&
          menus.sort(sortMenuByOrder).map(root => (
            <Menu
              active={isActive(root.href)}
              key={root.id}
              navigable={isNavigableMenu(root)}
              hoverable={root.href && root.children.length > 0}
            >
              {/* Only show dropdown icon if itself has children */}
              {root.href !== null && (
                <>
                  {root.isExternal ? (
                    <a href={root.href} target="_blank" rel="noopener noreferrer">
                      {root.name}
                    </a>
                  ) : (
                    <StyledLink to={root.href}>{root.name}</StyledLink>
                  )}
                </>
              )}
              {root.href === null && findMyName(root)}
              {root.children.length > 0 && <DropdownIcon />}

              <SubMenuSpot>
                <SubMenus>
                  <SubMenusWrapper>
                    {root.children.length > 0 &&
                      root.children.sort(sortMenuByOrder).map(subMenu => (
                        <React.Fragment key={subMenu.id}>
                          {isNavigableMenu(subMenu) && <NavigableSubMenu {...subMenu} columns={root.columns || 3} />}
                          {!isNavigableMenu(subMenu) && <UnnavigableSubMenu {...subMenu} columns={root.columns || 3} />}
                        </React.Fragment>
                      ))}
                  </SubMenusWrapper>
                </SubMenus>
              </SubMenuSpot>
            </Menu>
          ))}
      </RootMenus>

      <Actions>
        <Lang /> | {user ? <AuthorizedUser user={user} avatarSize="default" /> : <Login>log in</Login>}
      </Actions>
      <Pricing to="/pricing">
        <Menu pricing>PRICING</Menu>
      </Pricing>
    </MenuWrapper>
  </MenuBackground>
);

export default DesktopHeader;

const AuthorizedUser = styled(UserDropdown)`
  margin: 0 1em;

  a,
  div {
    color: black;
  }
`;

DesktopHeader.propTypes = {
  user: PropTypes.shape({}),
};

DesktopHeader.defaultProps = {
  user: null,
};
