import React from 'react';
import PropTypes from 'prop-types';

const CalendarEventCreateIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3.82031V1.82031C19 1.26803 18.5523 0.820312 18 0.820312C17.4477 0.820312 17 1.26803 17 1.82031V3.82031H19Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3.82031V1.82031C7 1.26803 6.55228 0.820312 6 0.820312C5.44772 0.820312 5 1.26803 5 1.82031V3.82031H7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 23.8203H21C22.6569 23.8203 24 22.4772 24 20.8203V7.82031C24 6.16346 22.6569 4.82031 21 4.82031H3C1.34315 4.82031 0 6.16346 0 7.82031V20.8203C0 22.4772 1.34315 23.8203 3 23.8203ZM2 9.82031H22V20.8203C22 21.3726 21.5523 21.8203 21 21.8203H3C2.44772 21.8203 2 21.3726 2 20.8203V9.82031Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 14.8203H13V11.8203H11V14.8203H8V16.8203H11V19.8203H13V16.8203H16V14.8203Z"
      fill="currentColor"
    />
  </svg>
);

CalendarEventCreateIcon.propTypes = {
  className: PropTypes.string,
};

CalendarEventCreateIcon.defaultProps = {
  className: '',
};

export default CalendarEventCreateIcon;
