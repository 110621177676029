import React from 'react';
import PropTypes from 'prop-types';

const QuestionMarkCircleIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0.820312C3.6 0.820312 0 4.42031 0 8.82031C0 13.2203 3.6 16.8203 8 16.8203C12.4 16.8203 16 13.2203 16 8.82031C16 4.42031 12.4 0.820312 8 0.820312ZM8 13.8203C7.4 13.8203 7 13.4203 7 12.8203C7 12.2203 7.4 11.8203 8 11.8203C8.6 11.8203 9 12.2203 9 12.8203C9 13.4203 8.6 13.8203 8 13.8203ZM9 9.82031C9 9.62031 9 9.52031 9.5 9.22031C10.1 8.82031 11 8.22031 11 6.82031C11 5.12031 9.7 3.82031 8 3.82031C6.9 3.82031 5.9 4.42031 5.4 5.32031L4.9 6.22031L6.6 7.22031L7.1 6.32031C7.3 6.02031 7.6 5.82031 8 5.82031C8.6 5.82031 9 6.22031 9 6.82031C9 7.12031 8.9 7.22031 8.4 7.52031C7.8 7.92031 7 8.52031 7 9.82031V10.8203H9V9.82031Z"
      fill="currentColor"
    />
  </svg>
);

QuestionMarkCircleIcon.propTypes = {
  className: PropTypes.string,
};

QuestionMarkCircleIcon.defaultProps = {
  className: '',
};

export default QuestionMarkCircleIcon;
