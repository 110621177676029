import React from 'react';
import PropTypes from 'prop-types';

const LibraryIcon = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} className={className} fill="none" viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M1 16.5h6M7 16.5h6M1 4h6v18H1zM7 4h6v18H7zM16 16l5-1"
    />
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M13 4.314L18.854 3l3.942 17.563-5.854 1.314z"
    />
  </svg>
);

LibraryIcon.propTypes = {
  className: PropTypes.string,
};

LibraryIcon.defaultProps = {
  className: '',
};

export default LibraryIcon;
