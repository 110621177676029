import React from 'react';
import styled from 'styled-components';

import { media } from '../../../utils/MediaResponsive';
import Icon from '../../Icon';
import { DesktopAndUp, TabletAndDown } from '../../Breakpoint';

const ScialIcon = styled(Icon)`
  font-size: 17px;
  color: ${props => props.theme.white};
  cursor: pointer;
  margin-right: 20px;
  background: #3c474f;
  border-radius: 2px;
  padding: 7px;
`;

const Wrapper = styled.footer`
  background-color: #2d3942;
  padding: 38px 6% 20px;

  .mobileScreen {
    display: none;
  }

  .desktopScreen {
    display: block;
  }

  ${media.tablet`
    .mobileScreen {
      display: block;
    }
    .desktopScreen {
    display: none;
  }
  `}
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-bottom: 15px;
  ${media.tablet`
  flex-direction: column;
  `}
`;

const ItemInfo = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : 1)};
  margin-bottom: 20px;
`;

const ColumnInfo = styled.div`
  flex: 1px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
  p {
    color: ${props => (props.color ? props.color : '#c0c4c6')};
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
  }

  ${media.tablet`
    max-width: none;
  `}
`;

const ColumnTitle = styled.div`
  height: 24px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
  color: ${props => props.theme.white};

  ${props =>
    props.noMargin &&
    `
    margin-bottom: 10px;
  `}
`;

const Address = styled.div`
  display: flex;
  color: ${props => props.theme.white};
  margin-bottom: 12px;
  font-size: 14px;

  p {
    margin-top: 16px;
    color: ${props => (props.color ? props.color : '#c0c4c6')};
  }
`;

const Privacy = styled.div`
  display: flex;
  border-top: 2px solid #97979720;

  ${media.tablet`
    flex-direction: column;
  `}
`;

const CopyRight = styled.div`
  flex: 1;
  margin-top: 20px;
  color: #c0c4c6;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;

const Tag = styled.a`
  color: ${props => props.theme.white};
  opacity: 0.8;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  text-decoration: underline;
  cursor: pointer;
`;

const WrapperStory = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
`;

const HighlightText = styled.span`
  font-weight: bold;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.white};
  background: #b22222;
  border-radius: 2px;
  padding: 3px 10px;
  margin-left: 10px;
`;

const Footer = () => (
  <Wrapper>
    <InfoWrapper>
      <ItemInfo flex={1.5}>
        <ColumnInfo maxWidth="288px">
          <ColumnTitle>Về chúng tôi</ColumnTitle>
          <p>
            DOL English, trước đây là IELTS Đình Lực, sáng tạo ra phương pháp học tiếng Anh thông minh, đi ngược lại
            quan niệm truyền thống rằng học tiếng Anh cần thời gian dài tích luỹ mà không cần tư duy. Tìm hiểu thêm về
            DOL tại:
          </p>
          <WrapperStory>
            <Tag href="">Câu chuyện</Tag>
            <Tag href="">Phương pháp</Tag>
            <Tag href="">Con người</Tag>
          </WrapperStory>
        </ColumnInfo>
      </ItemInfo>

      <ItemInfo>
        <ColumnInfo>
          <ColumnTitle>Courses</ColumnTitle>
          <p>IELTS</p>
          <p>SAT</p>
          <p>GMAT</p>
          <p className="desktopScreen">GRE</p>
          <p className="desktopScreen">Pronunciation</p>
          <p className="desktopScreen">Academic</p>
        </ColumnInfo>
        <ColumnInfo className="mobileScreen">
          <ColumnTitle />
          <p>GRE</p>
          <p>Pronunciation</p>
          <p>Academic</p>
        </ColumnInfo>
      </ItemInfo>

      <ItemInfo>
        <ColumnInfo>
          <ColumnTitle>DOL Ecosystem</ColumnTitle>
          <p>Learning Management</p>
          <p>Online Tests</p>
          <p>Essay corrections</p>
          <p className="desktopScreen">Vocabulary builder</p>
          <p className="desktopScreen">
            More at <Tag href="https://website.tech.dolenglish.vn/">DOLOnline.com</Tag>
          </p>
        </ColumnInfo>
        <ColumnInfo className="mobileScreen">
          <ColumnTitle />
          <p>Vocabulary builder</p>
          <p>
            More at <Tag href="https://website.tech.dolenglish.vn/">DOLOnline.com</Tag>
          </p>
        </ColumnInfo>
      </ItemInfo>
      <ItemInfo>
        <ColumnInfo>
          <ColumnTitle>Library</ColumnTitle>
          <p>Blog</p>
          <p>Ebooks</p>
          <p>Writing Sample</p>
          <p className="desktopScreen">Videos</p>
          <p className="desktopScreen">Free tests</p>
        </ColumnInfo>
        <ColumnInfo className="mobileScreen">
          <ColumnTitle />
          <p>Videos</p>
          <p>Free tests</p>
        </ColumnInfo>
      </ItemInfo>
      <ItemInfo flex={1.1}>
        <ColumnInfo>
          <ColumnTitle>More</ColumnTitle>
          <p>
            DOL Talents <HighlightText>we are hiring!</HighlightText>
          </p>
          <p>
            DOLOnline.com <HighlightText>TRY IT FREE</HighlightText>
          </p>
          <ColumnTitle noMargin>Contact us</ColumnTitle>

          <Address>
            <div>
              <a href="#">
                <ScialIcon type="Facebook" />
              </a>
              <a href="#">
                <ScialIcon type="Instagram" />
              </a>
              <a href="#">
                <ScialIcon type="LinkedinIcon" />
              </a>
            </div>
          </Address>
          <DesktopAndUp>
            <p>Phone +84 976 537 368</p>
            <p>51 Thanh Thai, Ward 5, District 10, HCMC</p>
          </DesktopAndUp>
          <TabletAndDown>
            <p style={{ color: 'white' }}>Phone +84 976 537 368</p>
            <p style={{ color: 'white' }}>51 Thanh Thai, Ward 5, District 10, HCMC</p>
          </TabletAndDown>
        </ColumnInfo>
      </ItemInfo>
    </InfoWrapper>

    <Privacy>
      <CopyRight>© 2018 Dol&#39;s English. All rights reserved.</CopyRight>
    </Privacy>
  </Wrapper>
);

export default Footer;
