import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1112 1.33388L0.521074 15.924C0.20843 16.2366 0.18445 16.6295 0.461125 16.9062C0.7378 17.1828 1.13068 17.1589 1.44332 16.8462L16.0334 2.25613C16.3461 1.94349 16.37 1.55061 16.0934 1.27393C15.8167 0.997258 15.4238 1.02124 15.1112 1.33388Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.44353 1.33388L16.0336 15.924C16.3463 16.2366 16.3702 16.6295 16.0936 16.9062C15.8169 17.1828 15.424 17.1589 15.1114 16.8462L0.521282 2.25613C0.208637 1.94349 0.184657 1.55061 0.461332 1.27393C0.738007 0.997258 1.13089 1.02124 1.44353 1.33388Z"
      fill="currentColor"
    />
  </svg>
);

CloseIcon.propTypes = {
  className: PropTypes.string,
};

CloseIcon.defaultProps = {
  className: '',
};

export default CloseIcon;
