import React from 'react';
import PropTypes from 'prop-types';

const Play = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.74571 6.00862L0.888571 10.8973C0.793143 10.9652 0.682286 11 0.571429 11C0.478857 11 0.386286 10.9762 0.301714 10.9279C0.116 10.8216 0 10.6144 0 10.3889V0.611481C0 0.385989 0.116 0.178829 0.301714 0.0724999C0.486857 -0.0338296 0.713143 -0.02283 0.888 0.103054L7.74514 4.99177C7.90457 5.10543 8 5.29609 8 5.5002C8 5.7043 7.90457 5.89496 7.74571 6.00862Z"
      fill="currentColor"
    />
  </svg>
);

Play.propTypes = {
  className: PropTypes.string,
};

Play.defaultProps = {
  className: '',
};

export default Play;
