import React from 'react';
import PropTypes from 'prop-types';

const TabletToolbarIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 23.5H3C1.9 23.5 1 22.6 1 21.5V3.5C1 2.4 1.9 1.5 3 1.5H19C20.1 1.5 21 2.4 21 3.5V21.5C21 22.6 20.1 23.5 19 23.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path d="M3 16H20" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 20.5C11.5523 20.5 12 20.0523 12 19.5C12 18.9477 11.5523 18.5 11 18.5C10.4477 18.5 10 18.9477 10 19.5C10 20.0523 10.4477 20.5 11 20.5Z"
      fill="#6B747A"
    />
  </svg>
);

TabletToolbarIcon.propTypes = {
  className: PropTypes.string,
};

TabletToolbarIcon.defaultProps = {
  className: '',
};

export default TabletToolbarIcon;
