import React from 'react';
import PropTypes from 'prop-types';

const SpeakingSkill = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 157 157" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <circle cx="74.8213" cy="70.3276" r="60" fill="white" />
    </g>
    <path
      d="M85.6129 57.6202C83.6019 54.0992 79.7572 51.6932 75.3211 51.6932C70.885 51.6932 67.0403 54.0992 65.0293 57.6202L65.2659 59.322H85.3763L85.6129 57.6202Z"
      fill="white"
    />
    <path
      d="M75.3211 45.8248C78.5877 45.8248 81.2359 43.1974 81.2359 39.9564C81.2359 36.7154 78.5877 34.0881 75.3211 34.0881C72.0544 34.0881 69.4062 36.7154 69.4062 39.9564C69.4062 43.1974 72.0544 45.8248 75.3211 45.8248Z"
      fill="white"
    />
    <path
      d="M75.3211 47.5853C71.0624 47.5853 67.6318 44.1817 67.6318 39.9564C67.6318 35.7312 71.0624 32.3276 75.3211 32.3276C79.5798 32.3276 83.0104 35.7312 83.0104 39.9564C83.0104 44.1817 79.5798 47.5853 75.3211 47.5853ZM75.3211 35.8486C73.0143 35.8486 71.1807 37.6678 71.1807 39.9564C71.1807 42.2451 73.0143 44.0643 75.3211 44.0643C77.6279 44.0643 79.4615 42.2451 79.4615 39.9564C79.4615 37.6678 77.6279 35.8486 75.3211 35.8486Z"
      fill="#444B54"
    />
    <path
      d="M85.6129 59.3807C85.0214 59.3807 84.3708 59.0873 84.075 58.5005C82.3006 55.3902 78.9291 53.4537 75.3211 53.4537C71.7131 53.4537 68.3416 55.3902 66.5672 58.5005C66.094 59.322 65.0293 59.6154 64.1421 59.146C63.314 58.6765 63.0183 57.6202 63.4915 56.7399C65.9165 52.5147 70.4709 49.874 75.3211 49.874C80.1713 49.874 84.7257 52.5147 87.1508 56.7399C87.6239 57.5615 87.3282 58.6765 86.5001 59.146C86.2044 59.322 85.9086 59.3807 85.6129 59.3807Z"
      fill="#444B54"
    />
    <path
      d="M85.6129 104.567C83.6019 100.987 79.7572 98.64 75.3211 98.64C70.885 98.64 67.0403 101.046 65.0293 104.567L65.2659 106.269H85.3763L85.6129 104.567Z"
      fill="white"
    />
    <path
      d="M75.3211 92.7717C78.5877 92.7717 81.2359 90.1443 81.2359 86.9033C81.2359 83.6623 78.5877 81.035 75.3211 81.035C72.0544 81.035 69.4062 83.6623 69.4062 86.9033C69.4062 90.1443 72.0544 92.7717 75.3211 92.7717Z"
      fill="white"
    />
    <path
      d="M75.3211 94.5322C71.0624 94.5322 67.6318 91.1285 67.6318 86.9033C67.6318 82.6781 71.0624 79.2744 75.3211 79.2744C79.5798 79.2744 83.0104 82.6781 83.0104 86.9033C83.0104 91.1285 79.5798 94.5322 75.3211 94.5322ZM75.3211 82.7955C73.0143 82.7955 71.1807 84.6147 71.1807 86.9033C71.1807 89.192 73.0143 91.0112 75.3211 91.0112C77.6279 91.0112 79.4615 89.192 79.4615 86.9033C79.4615 84.6147 77.6279 82.7955 75.3211 82.7955Z"
      fill="#444B54"
    />
    <path
      d="M85.6129 106.328C85.0214 106.328 84.3708 106.034 84.075 105.447C82.3006 102.337 78.9291 100.401 75.3211 100.401C71.7131 100.401 68.3416 102.337 66.5672 105.447C66.094 106.269 65.0293 106.562 64.1421 106.093C63.314 105.623 63.0183 104.567 63.4915 103.687C65.9165 99.4616 70.4709 96.8208 75.3211 96.8208C80.1713 96.8208 84.7257 99.4616 87.1508 103.687C87.6239 104.508 87.3282 105.623 86.5001 106.093C86.2044 106.269 85.9086 106.328 85.6129 106.328Z"
      fill="#444B54"
    />
    <path
      d="M60.1793 104.567C58.1683 100.987 54.3236 98.64 49.8875 98.64C45.4514 98.64 41.6067 101.046 39.5957 104.567L39.8323 106.269H59.9427L60.1793 104.567Z"
      fill="white"
    />
    <path
      d="M49.8875 92.7717C53.1542 92.7717 55.8023 90.1443 55.8023 86.9033C55.8023 83.6623 53.1542 81.035 49.8875 81.035C46.6208 81.035 43.9727 83.6623 43.9727 86.9033C43.9727 90.1443 46.6208 92.7717 49.8875 92.7717Z"
      fill="white"
    />
    <path
      d="M49.8875 94.5322C45.6288 94.5322 42.1982 91.1285 42.1982 86.9033C42.1982 82.6781 45.6288 79.2744 49.8875 79.2744C54.1462 79.2744 57.5768 82.6781 57.5768 86.9033C57.5768 91.1285 54.1462 94.5322 49.8875 94.5322ZM49.8875 82.7955C47.5807 82.7955 45.7471 84.6147 45.7471 86.9033C45.7471 89.192 47.5807 91.0112 49.8875 91.0112C52.1943 91.0112 54.0279 89.192 54.0279 86.9033C54.0279 84.6147 52.1943 82.7955 49.8875 82.7955Z"
      fill="#444B54"
    />
    <path
      d="M39.5957 106.328C39.3 106.328 39.0042 106.269 38.7085 106.093C37.8804 105.623 37.5847 104.567 38.0579 103.687C40.4829 99.4616 45.0374 96.8208 49.8875 96.8208C53.3181 96.8208 56.5713 98.0532 59.0555 100.342C59.7653 100.987 59.8244 102.102 59.1738 102.807C58.5232 103.511 57.3993 103.569 56.6896 102.924C54.856 101.222 52.4309 100.283 49.8875 100.283C46.2795 100.283 42.908 102.22 41.1336 105.33C40.8378 106.034 40.1872 106.328 39.5957 106.328Z"
      fill="#444B54"
    />
    <path
      d="M111.047 104.567C109.036 100.987 105.191 98.64 100.755 98.64C96.3191 98.64 92.4744 101.046 90.4634 104.567L90.7 106.269H110.81L111.047 104.567Z"
      fill="white"
    />
    <path
      d="M100.755 92.7717C104.022 92.7717 106.67 90.1443 106.67 86.9033C106.67 83.6623 104.022 81.035 100.755 81.035C97.4885 81.035 94.8403 83.6623 94.8403 86.9033C94.8403 90.1443 97.4885 92.7717 100.755 92.7717Z"
      fill="white"
    />
    <path
      d="M100.755 94.5322C96.4965 94.5322 93.0659 91.1285 93.0659 86.9033C93.0659 82.6781 96.4965 79.2744 100.755 79.2744C105.014 79.2744 108.444 82.6781 108.444 86.9033C108.444 91.1285 105.014 94.5322 100.755 94.5322ZM100.755 82.7955C98.4484 82.7955 96.6148 84.6147 96.6148 86.9033C96.6148 89.192 98.4484 91.0112 100.755 91.0112C103.062 91.0112 104.896 89.192 104.896 86.9033C104.896 84.6147 103.062 82.7955 100.755 82.7955Z"
      fill="#444B54"
    />
    <path
      d="M111.047 106.328C110.455 106.328 109.805 106.034 109.509 105.447C107.735 102.337 104.363 100.401 100.755 100.401C98.2117 100.401 95.7866 101.339 93.953 103.041C93.2432 103.687 92.1194 103.628 91.4688 102.924C90.8182 102.22 90.8773 101.105 91.5871 100.459C94.1305 98.1706 97.3836 96.8795 100.814 96.8795C105.724 96.8795 110.219 99.5203 112.644 103.745C113.117 104.567 112.821 105.682 111.993 106.152C111.638 106.269 111.343 106.328 111.047 106.328Z"
      fill="#444B54"
    />
    <path
      d="M101.346 71.0587H49.2959C48.2904 71.0587 47.5215 70.2958 47.5215 69.2982C47.5215 68.3006 48.2904 67.5377 49.2959 67.5377H101.346C102.352 67.5377 103.121 68.3006 103.121 69.2982C103.121 70.2958 102.352 71.0587 101.346 71.0587Z"
      fill="#D22222"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0.821289"
        y="0.327576"
        width="156"
        height="156"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx="4" dy="8" />
        <feGaussianBlur stdDeviation="9" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

SpeakingSkill.propTypes = {
  className: PropTypes.string,
};

SpeakingSkill.defaultProps = {
  className: '',
};

export default SpeakingSkill;
