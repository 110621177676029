import styled, { css } from 'styled-components';

const outline = css`
  background-color: ${props => props.theme.transparent};
  color: ${props => props.theme.white};
  border: 2px solid ${props => props.theme.white};

  &:hover {
    color: ${props => props.theme.brand};
    background-color: ${props => props.theme.white};
  }
`;

const RoundButton = styled.button`
  background-color: ${props => props.theme.brand};
  color: ${props => props.theme.white};
  height: 48px;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  border: none;
  font-weight: bold;
  font-size: 16px;
  padding: 0 40px;

  &:hover {
    background-color: ${props => props.theme.redButtonHoverColor};
  }
  transition: 0.5s all ease;
  ${props => props.outline && outline}
  ${props =>
    props.inActive &&
    css`
      background-color: ${props.theme.inActiveBGColor};
      color: ${props.theme.inActiveBGTextColor};

      &:hover {
        color: ${props.theme.white};
      }
    `};
`;

export default RoundButton;
