import React from 'react';
import PropTypes from 'prop-types';

const LikeIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1858 4.37578H7.17578V1.57578C7.17578 0.525781 6.61578 0.175781 5.77578 0.175781C5.56578 0.175781 5.35578 0.315781 5.35578 0.525781C5.35578 0.525781 3.74578 5.77578 3.67578 5.77578V11.3758H9.69578C10.6058 11.3758 11.3758 10.6758 11.5158 9.76578L12.0758 6.54578C12.1458 5.98578 12.0058 5.42578 11.6558 5.07578C11.3058 4.58578 10.7458 4.37578 10.1858 4.37578Z"
      fill="currentColor"
    />
    <rect x="0.875" y="5.77539" width="1.4" height="5.6" fill="currentColor" />
  </svg>
);

LikeIcon.propTypes = {
  className: PropTypes.string,
};

LikeIcon.defaultProps = {
  className: '',
};

export default LikeIcon;
