import React from 'react';
import PropTypes from 'prop-types';

const PersonalizedIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 59 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9068 66H53.8369C56.6625 66 58.9532 63.6783 58.9532 60.8143V18.4448C58.9532 17.2126 58.4684 16.0299 57.6036 15.1521L44.0523 1.39873C43.1706 0.503854 41.9668 0 40.7105 0H12.9068C10.0812 0 7.79053 2.32172 7.79053 5.18571L7.79053 60.8143C7.79053 63.6783 10.0812 66 12.9068 66Z"
      fill="#D84D44"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="0" width="52" height="66">
      <path
        d="M12.9068 66H53.8369C56.6625 66 58.9532 63.6783 58.9532 60.8143V18.4448C58.9532 17.2126 58.4684 16.0299 57.6036 15.1521L44.0523 1.39873C43.1706 0.503854 41.9668 0 40.7105 0H12.9068C10.0812 0 7.79053 2.32172 7.79053 5.18571L7.79053 60.8143C7.79053 63.6783 10.0812 66 12.9068 66Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.95405 19.9199H39.4183V50.4356L7.8584 55.1561L7.95405 19.9199Z"
        fill="#670C07"
      />
    </g>
    <path
      d="M15.7724 62.3837H50.9712C53.4011 62.3837 55.371 60.38 55.371 57.9084V21.6015C55.371 20.3722 54.8885 19.1919 54.0272 18.3147L42.7506 6.82944C41.8685 5.93096 40.6622 5.4248 39.403 5.4248H15.7724C13.3424 5.4248 11.3726 7.42848 11.3726 9.90015L11.3726 57.9084C11.3726 60.38 13.3424 62.3837 15.7724 62.3837Z"
      stroke="white"
      strokeWidth="1.8"
    />
    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="4" width="47" height="60">
      <path
        d="M15.7724 62.3837H50.9712C53.4011 62.3837 55.371 60.38 55.371 57.9084V21.6015C55.371 20.3722 54.8885 19.1919 54.0272 18.3147L42.7506 6.82944C41.8685 5.93096 40.6622 5.4248 39.403 5.4248H15.7724C13.3424 5.4248 11.3726 7.42848 11.3726 9.90015L11.3726 57.9084C11.3726 60.38 13.3424 62.3837 15.7724 62.3837Z"
        fill="white"
        stroke="white"
        strokeWidth="1.8"
      />
    </mask>
    <g mask="url(#mask1)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.61627 17.4434H39.2441C40.689 17.4434 41.8603 18.6746 41.8603 20.1934V47.6934C41.8603 49.2121 40.689 50.4434 39.2441 50.4434H2.61627C1.17134 50.4434 0 49.2121 0 47.6934V20.1934C0 18.6746 1.17134 17.4434 2.61627 17.4434Z"
      fill="#BB2B22"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 33.9434C10 28.1539 14.7106 23.4434 20.5 23.4434C26.2894 23.4434 31 28.1539 31 33.9434C31 39.7328 26.2894 44.4434 20.5 44.4434C14.7106 44.4434 10 39.7328 10 33.9434ZM27.0625 40.351V40.3608C28.6834 38.7038 29.6875 36.4397 29.6875 33.9434C29.6875 28.8771 25.5662 24.7559 20.5 24.7559C15.4338 24.7559 11.3125 28.8771 11.3125 33.9434C11.3125 36.4397 12.3166 38.7038 13.9375 40.3608V40.351C13.9375 39.2222 14.5176 38.1729 15.4738 37.5724L16.9379 36.653C17.8258 37.7575 19.0339 38.5371 20.5 38.5371C21.9661 38.5371 23.1742 37.7575 24.0621 36.653L25.5262 37.5724C26.4824 38.1729 27.0625 39.2222 27.0625 40.351Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 28.0371C18.3252 28.0371 16.5625 29.7998 16.5625 31.9746C16.5625 34.1494 18.1756 37.2246 20.5 37.2246C22.8513 37.2246 24.4375 34.1494 24.4375 31.9746C24.4375 29.7998 22.6748 28.0371 20.5 28.0371Z"
      fill="white"
    />
  </svg>
);

PersonalizedIcon.propTypes = {
  className: PropTypes.string,
};

PersonalizedIcon.defaultProps = {
  className: '',
};

export default PersonalizedIcon;
