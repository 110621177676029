import React from 'react';
import PropTypes from 'prop-types';

const ReachScoreIcon = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0978 35.2324C27.8233 35.2324 35.7074 27.3454 35.7074 17.6162C35.7074 7.88704 27.8233 0 18.0978 0C8.37235 0 0.488281 7.88704 0.488281 17.6162C0.488281 27.3454 8.37235 35.2324 18.0978 35.2324Z"
      fill="#AC1B1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7946 25.9062H12.4002C10.97 25.9062 9.81055 24.7463 9.81055 23.3156V11.9169C9.81055 10.4861 10.97 9.32623 12.4002 9.32623H13.954V11.3987H12.4002C12.1141 11.3987 11.8823 11.6307 11.8823 11.9169V23.3156C11.8823 23.6017 12.1141 23.8337 12.4002 23.8337H23.7946C24.0807 23.8337 24.3125 23.6017 24.3125 23.3156V11.9169C24.3125 11.6307 24.0807 11.3987 23.7946 11.3987H22.2408V9.32623H23.7946C25.2248 9.32623 26.3843 10.4861 26.3843 11.9169V23.3156C26.3843 24.7463 25.2248 25.9062 23.7946 25.9062Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2054 17.6162L18.0978 15.0256L14.9902 17.6162V9.32623H21.2054V17.6162Z"
      fill="white"
    />
  </svg>
);

ReachScoreIcon.propTypes = {
  className: PropTypes.string,
};

ReachScoreIcon.defaultProps = {
  className: '',
};

export default ReachScoreIcon;
