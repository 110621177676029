import React from 'react';
import PropTypes from 'prop-types';

const Facebook = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} className={className} fill="none" viewBox="0 0 22 22">
    <path
      d="M19.0362 0H2.96552C1.32828 0 0.000976562 1.32725 0.000976562 2.96454V19.0353C0.000976562 20.6726 1.32823 21.9999 2.96552 21.9999H10.8915L10.905 14.1383H8.86259C8.59716 14.1383 8.38173 13.9237 8.38071 13.6583L8.37091 11.1242C8.36989 10.8573 8.58597 10.6404 8.85285 10.6404H10.8916V8.1918C10.8916 5.35023 12.627 3.80297 15.1619 3.80297H17.2419C17.5081 3.80297 17.7239 4.01872 17.7239 4.28491V6.42168C17.7239 6.68775 17.5082 6.90346 17.2422 6.90362L15.9657 6.90421C14.5872 6.90421 14.3202 7.55928 14.3202 8.52062V10.6404H17.3494C17.638 10.6404 17.8619 10.8925 17.8279 11.1791L17.5276 13.7132C17.4988 13.9557 17.2932 14.1384 17.049 14.1384H14.3337L14.3202 22H19.0363C20.6736 22 22.0008 20.6728 22.0008 19.0355V2.96454C22.0008 1.32725 20.6735 0 19.0362 0Z"
      fill="currentColor"
    />
  </svg>
);

Facebook.propTypes = {
  className: PropTypes.string,
};

Facebook.defaultProps = {
  className: '',
};

export default Facebook;
