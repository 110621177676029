import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { DesktopAndUp, TabletAndDown } from '../Breakpoint';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';

const Wrapper = styled.header`
  background: ${props => props.theme.bgHeader};
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
`;

function Header({ user }) {
  return (
    <Wrapper>
      <TabletAndDown>
        <MobileHeader user={user} />
      </TabletAndDown>
      <DesktopAndUp>
        <DesktopHeader user={user} />
      </DesktopAndUp>
    </Wrapper>
  );
}

export default Header;
export { menuHeightDesktop } from './DesktopHeader';
export { menuHeightMobile } from './MobileHeader';

Header.propTypes = {
  user: PropTypes.shape({}),
};

Header.defaultProps = {
  user: null,
};
