import React from 'react';
import PropTypes from 'prop-types';

const Speaking = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 6.3999V16.7999H17.7273V19.9999L12.8182 16.7999H12"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="square"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7273 4H3V13.6H6.27273V17.6L12 13.6H17.7273V4V4Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="square"
    />
  </svg>
);

Speaking.propTypes = {
  className: PropTypes.string,
};

Speaking.defaultProps = {
  className: '',
};

export default Speaking;
