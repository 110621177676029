import React from 'react';
import PropTypes from 'prop-types';

const DotIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6C0 9.31371 2.68629 12 6 12C9.31234 11.9967 11.9967 9.31234 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6ZM2 6C2 3.79086 3.79086 2 6 2C7.06087 2 8.07828 2.42143 8.82843 3.17157C9.57857 3.92172 10 4.93913 10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8Z"
      fill="currentColor"
    />
  </svg>
);

DotIcon.propTypes = {
  className: PropTypes.string,
};

DotIcon.defaultProps = {
  className: '',
};

export default DotIcon;
