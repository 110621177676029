import React from 'react';
import PropTypes from 'prop-types';

const UnLockCircleIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 10.726V6C14 2.691 11.309 0 8 0C4.691 0 2 2.691 2 6V10.726C0.759 12.136 0 13.979 0 16C0 20.411 3.589 24 8 24C12.411 24 16 20.411 16 16C16 13.979 15.241 12.136 14 10.726ZM9 17.816V20H7V17.816C5.839 17.402 5 16.302 5 15C5 13.346 6.346 12 8 12C9.654 12 11 13.346 11 15C11 16.302 10.162 17.402 9 17.816ZM8 8C9.458 8 10.822 8.398 12 9.082V6C12 3.794 10.206 2 8 2C5.794 2 4 3.794 4 6V9.082C5.178 8.398 6.542 8 8 8Z"
      fill="currentColor"
    />
    <path d="M7.5 6.5C7.5 6.5 17 8 17 15.5" stroke="#F2F4F5" strokeWidth="2" />
  </svg>
);

UnLockCircleIcon.propTypes = {
  className: PropTypes.string,
};

UnLockCircleIcon.defaultProps = {
  className: '',
};

export default UnLockCircleIcon;
