import React from 'react';
import PropTypes from 'prop-types';

const ReadingSkill = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 157 157" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <circle cx="74.8213" cy="70.3276" r="60" fill="white" />
    </g>
    <path
      d="M74.2345 79.1207H88.3215C89.3193 79.1207 90.0823 78.3586 90.0823 77.3621V69.1552C90.0823 68.1586 89.3193 67.3965 88.3215 67.3965H74.2345C73.2367 67.3965 72.4736 68.1586 72.4736 69.1552V77.3621C72.4736 78.3586 73.2367 79.1207 74.2345 79.1207Z"
      fill="#D22222"
    />
    <path
      d="M94.1907 43.9483C94.1907 40.7241 96.832 38.0862 100.06 38.0862H55.4516C52.2233 38.0862 49.582 40.7241 49.582 43.9483C49.582 47.1724 52.2233 49.8103 55.4516 49.8103H100.06"
      fill="white"
    />
    <path
      d="M100.06 48.0517H67.1909C66.193 48.0517 65.43 48.8138 65.43 49.8103C65.43 50.8069 66.193 51.569 67.1909 51.569H98.2995V100.81H55.4517C53.1626 100.81 51.343 98.9931 51.343 96.7069V50.3379C52.5169 51.1 53.9256 51.569 55.4517 51.569H60.1474C61.1452 51.569 61.9082 50.8069 61.9082 49.8103C61.9082 48.8138 61.1452 48.0517 60.1474 48.0517H55.4517C53.1626 48.0517 51.343 46.2345 51.343 43.9483C51.343 41.6621 53.1626 39.8448 55.4517 39.8448H93.6039C92.8409 41.0172 92.43 42.4241 92.43 43.9483C92.43 44.9448 93.193 45.7069 94.1909 45.7069C95.1887 45.7069 95.9517 44.9448 95.9517 43.9483C95.9517 41.6621 97.7713 39.8448 100.06 39.8448C101.058 39.8448 101.821 39.0827 101.821 38.0862C101.821 37.0896 101.058 36.3276 100.06 36.3276H55.4517C51.2256 36.3276 47.8213 39.7276 47.8213 43.9483V96.7069C47.8213 100.928 51.2256 104.328 55.4517 104.328H100.06C101.058 104.328 101.821 103.566 101.821 102.569V49.8103C101.821 48.8138 101.058 48.0517 100.06 48.0517Z"
      fill="#444B54"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0.821289"
        y="0.327576"
        width="156"
        height="156"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx="4" dy="8" />
        <feGaussianBlur stdDeviation="9" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

ReadingSkill.propTypes = {
  className: PropTypes.string,
};

ReadingSkill.defaultProps = {
  className: '',
};

export default ReadingSkill;
