import React from 'react';
import PropTypes from 'prop-types';

const VocabularyMadeSimple = ({ className, ...rest }) => (
  <svg className={className} {...rest} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30">
    <path
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M18 13h-3.5a2.5 2.5 0 1 1 0-5H18v5z"
      clipRule="evenodd"
    />
    <path stroke="currentColor" strokeLinecap="square" strokeWidth="2" d="M14 5h2a2 2 0 0 1 2 2v6M6 14v11" />
    <path
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M9 25H6v-6h3a3 3 0 1 1 0 6z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M24.732 18.999A2 2 0 0 0 23 18h-2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h2c.74 0 1.387-.402 1.732-1"
    />
  </svg>
);

VocabularyMadeSimple.propTypes = {
  className: PropTypes.string,
};

VocabularyMadeSimple.defaultProps = {
  className: '',
};

export default VocabularyMadeSimple;
