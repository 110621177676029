import React from 'react';
import PropTypes from 'prop-types';

const UniversityHat = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.3548 8.41162L1.13271 8.29994L1.3548 8.18827L15.397 1.12709L15.4531 1.09886L15.5093 1.12709L29.0444 7.93334L29.1133 7.96796V8.04502V17.0999C29.1133 18.6088 27.8927 19.8374 26.3906 19.8374H26.2656V19.7124V18.8874V18.7624H26.3906C27.3009 18.7624 28.043 18.0172 28.043 17.0999V9.17014L24.7383 10.8319V17.3549V17.4319L24.6694 17.4665L15.5093 22.0728L15.4531 22.101L15.397 22.0728L6.23682 17.4665L6.16797 17.4319V17.3549V10.8319L1.3548 8.41162ZM23.668 16.768V11.3701L15.5093 15.4728L15.4531 15.501L15.397 15.4728L7.23829 11.3701V16.768L15.4531 20.8988L23.668 16.768ZM15.4531 14.2989L27.3827 8.29994L15.4531 2.30103L3.52354 8.29994L15.4531 14.2989Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
  </svg>
);

UniversityHat.propTypes = {
  className: PropTypes.string,
};

UniversityHat.defaultProps = {
  className: '',
};

export default UniversityHat;
