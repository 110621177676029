import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

const LogoImage = ({ theme, ...rest }) => (
  <StaticQuery
    query={graphql`
      query {
        logoImage: file(relativePath: { eq: "dolonline-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 114) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => <Img {...rest} fluid={data.logoImage.childImageSharp.fluid} />}
  />
);
LogoImage.propTypes = {
  theme: PropTypes.instanceOf(Object).isRequired,
};
export default withTheme(LogoImage);
