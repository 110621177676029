export default {
  transparent: 'transparent',
  textColor: '#232323',
  brand: '#D22222',
  bgGray: '#323232',
  white: '#ffffff',
  black: '#000000',
  bgHeader: '#2D3942',
  whiteFB: '#fbfbfb',
  whiteF3: '#f3f3f3',
  inActiveBGColor: '#EFF0F2',
  inActiveBGTextColor: '#8F9193',
  redButtonColor: '#BB2B22',
  redButtonHoverColor: '#9A231C',
  navItemTextColor: '#2c3841',
  lightGreen: '#22BB3E',
};
