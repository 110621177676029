import React from 'react';
import PropTypes from 'prop-types';

const VocabularySkill = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 157 157" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <circle cx="74.8213" cy="70.3276" r="60" fill="white" />
    </g>
    <path
      d="M99.3289 76.8124C97.2945 76.8124 95.3054 75.9954 93.8587 74.543C92.4121 75.9954 90.4681 76.8124 88.3886 76.8124H68.9944C66.508 76.8124 64.4736 74.7699 64.4736 72.2736V47.3106C64.4736 44.8143 66.508 42.7719 68.9944 42.7719H93.8587C96.3452 42.7719 98.3795 44.8143 98.3795 47.3106V67.3718C98.3795 70.095 99.5097 72.6367 101.363 74.4976C102.222 75.36 101.634 76.8577 100.414 76.8577H99.3289V76.8124Z"
      fill="#444B54"
    />
    <path
      d="M97.8016 104.328C98.6923 104.328 99.4143 103.603 99.4143 102.709C99.4143 101.814 98.6923 101.089 97.8016 101.089C96.911 101.089 96.189 101.814 96.189 102.709C96.189 103.603 96.911 104.328 97.8016 104.328Z"
      fill="#3F4A54"
    />
    <path
      d="M90.8135 102.709H83.8791C81.4602 102.709 79.095 101.737 77.3748 100.01C75.6546 101.737 73.3432 102.709 70.8704 102.709H47.8096C44.8531 102.709 42.4341 100.28 42.4341 97.3117V67.6292C42.4341 64.6609 44.8531 62.2323 47.8096 62.2323H77.3748C80.3313 62.2323 82.7503 64.6609 82.7503 67.6292V91.4831C82.7503 96.5561 85.9756 101.089 90.8135 102.709Z"
      fill="white"
    />
    <path
      d="M90.8134 104.328H83.879C80.9762 104.328 78.2885 103.194 76.2458 101.143C75.6007 100.496 75.6007 99.4704 76.2458 98.8768C76.8909 98.2292 77.9122 98.2292 78.5035 98.8768C79.9549 100.334 81.8363 101.089 83.879 101.089H85.1691C82.6426 98.6069 81.1375 95.2069 81.1375 91.4831V67.6292C81.1375 65.5244 79.4711 63.8514 77.3746 63.8514H47.8094C45.713 63.8514 44.0466 65.5244 44.0466 67.6292V97.3117C44.0466 99.4165 45.713 101.089 47.8094 101.089H70.8703C71.7841 101.089 72.4829 101.791 72.4829 102.709C72.4829 103.626 71.7841 104.328 70.8703 104.328H47.8094C43.9391 104.328 40.8213 101.197 40.8213 97.3117V67.6292C40.8213 63.7435 43.9391 60.6133 47.8094 60.6133H77.3746C81.245 60.6133 84.3628 63.7435 84.3628 67.6292V91.4831C84.3628 95.9085 87.158 99.7943 91.2972 101.197C92.0497 101.467 92.4798 102.223 92.3723 102.978C92.2648 103.734 91.6197 104.328 90.8134 104.328Z"
      fill="#444B54"
    />
    <path
      d="M71.9943 92.6707L63.9311 71.0833C63.7161 70.4897 63.1248 70.058 62.426 70.058C61.7271 70.058 61.1358 70.4897 60.9208 71.0833L52.8576 92.6707C52.535 93.5341 52.9651 94.4516 53.8252 94.7754C54.6315 95.0992 55.5991 94.6675 55.9216 93.804L57.5343 89.4326H67.3714L68.9841 93.804C69.2528 94.4516 69.8441 94.8294 70.4892 94.8294C70.7042 94.8294 70.8655 94.7754 71.0805 94.7214C71.8868 94.4516 72.3169 93.5341 71.9943 92.6707ZM58.7169 86.2484L62.426 76.2643L66.135 86.2484H58.7169Z"
      fill="#D22222"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0.821289"
        y="0.327576"
        width="156"
        height="156"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx="4" dy="8" />
        <feGaussianBlur stdDeviation="9" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

VocabularySkill.propTypes = {
  className: PropTypes.string,
};

VocabularySkill.defaultProps = {
  className: '',
};

export default VocabularySkill;
