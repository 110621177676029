import React from 'react';
import PropTypes from 'prop-types';

const BookIcon = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} className={className} fill="none" viewBox="0 0 19 25">
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth=".25"
      d="M2.733 1.325h14.779v17.862H2.733c-.504 0-.97.143-1.367.383V2.627c0-.712.608-1.302 1.367-1.302zM18.131.128H2.733C1.301.128.128 1.244.128 2.628v19.744c0 1.384 1.173 2.5 2.605 2.5h15.398c.337 0 .619-.262.619-.598V.726a.609.609 0 0 0-.62-.598zM2.733 23.675c-.759 0-1.367-.59-1.367-1.303v-.684c0-.714.608-1.303 1.367-1.303h14.779v3.29H2.733zM5.201 3.491a.609.609 0 0 0-.62.599c0 .336.283.598.62.598h10.442c.337 0 .62-.262.62-.598a.609.609 0 0 0-.62-.599H5.201zm0 2.422a.609.609 0 0 0-.62.599c0 .336.283.598.62.598h10.442c.337 0 .62-.263.62-.598a.609.609 0 0 0-.62-.599H5.201z"
    />
  </svg>
);

BookIcon.propTypes = {
  className: PropTypes.string,
};

BookIcon.defaultProps = {
  className: '',
};

export default BookIcon;
