import React from 'react';
import PropTypes from 'prop-types';

const PencilIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 16 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0.820312H15C15.553 0.820312 16 1.26831 16 1.82031V15.8203C16 16.0623 15.912 16.2963 15.753 16.4793L8.753 24.4793C8.562 24.6953 8.288 24.8203 8 24.8203C7.712 24.8203 7.438 24.6953 7.247 24.4793L0.247 16.4793C0.088 16.2963 0 16.0623 0 15.8203V1.82031C0 1.26831 0.447 0.820312 1 0.820312H4V9.82031H6V0.820312H10V9.82031H12V0.820312ZM2.463 15.9733L6.704 20.8203H9.296L13.538 15.9723L11.094 14.1403L8.707 16.5273C8.512 16.7223 8.256 16.8203 8 16.8203C7.744 16.8203 7.488 16.7223 7.294 16.5283L4.907 14.1413L2.463 15.9733Z"
      fill="currentColor"
    />
  </svg>
);

PencilIcon.propTypes = {
  className: PropTypes.string,
};

PencilIcon.defaultProps = {
  className: '',
};

export default PencilIcon;
