import React from 'react';
import PropTypes from 'prop-types';

const Pause = ({ className, ...rest }) => (
  <svg className={className} {...rest} viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="3" height="11" fill="currentColor" />
    <rect x="5" width="3" height="11" fill="currentColor" />
  </svg>
);

Pause.propTypes = {
  className: PropTypes.string,
};

Pause.defaultProps = {
  className: '',
};

export default Pause;
