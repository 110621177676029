import React from 'react';
import PropTypes from 'prop-types';

const ViewAsThumbnail = ({ className, ...rest }) => (
  <svg className={className} {...rest} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22">
    <g fill="#444" fillRule="evenodd" clipRule="evenodd" opacity=".5">
      <path d="M22 10V2a2 2 0 0 0-2-2h-8v10h10zM10 0H2a2 2 0 0 0-2 2v8h10V0zM0 12v8a2 2 0 0 0 2 2h8V12H0zM12 22h8a2 2 0 0 0 2-2v-8H12v10z" />
    </g>
  </svg>
);

ViewAsThumbnail.propTypes = {
  className: PropTypes.string,
};

ViewAsThumbnail.defaultProps = {
  className: '',
};

export default ViewAsThumbnail;
