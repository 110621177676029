import React from 'react';
import PropTypes from 'prop-types';

const FontAIcon = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="1em" height="1em" stroke="currentColor" strokeWidth="1.8" strokeLinecap="square" />
    <path
      d="M5.54541 16.3635L10.0909 4.63623H11.909L16.4545 16.3635"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="square"
    />
    <path d="M8 11.4545H14.0268" stroke="currentColor" strokeWidth="1.8" strokeLinecap="square" />
    <path d="M3.72705 17.3637H7.36341" stroke="currentColor" strokeWidth="1.8" strokeLinecap="square" />
    <path d="M14.6362 17.3637H18.2726" stroke="currentColor" strokeWidth="1.8" strokeLinecap="square" />
  </svg>
);

FontAIcon.propTypes = {
  className: PropTypes.string,
};

FontAIcon.defaultProps = {
  className: '',
};

export default FontAIcon;
