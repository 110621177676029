import React from 'react';
import PropTypes from 'prop-types';

const ListeningSkill = ({ className, ...rest }) => (
  <svg {...rest} className={className} viewBox="0 0 157 157" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <circle cx="74.8213" cy="70.3276" r="60" fill="white" />
    </g>
    <path
      d="M68.7141 109.328H56.5963C51.7909 109.328 47.8213 105.358 47.8213 100.553V79.6597C47.8213 74.7847 51.7909 70.8847 56.5963 70.8847H68.7141C73.5891 70.8847 77.4891 74.8544 77.4891 79.6597V100.553C77.4891 105.358 73.5195 109.328 68.7141 109.328ZM56.5963 75.0633C54.0891 75.0633 51.9999 77.1526 51.9999 79.6597V100.553C51.9999 103.06 54.0891 105.149 56.5963 105.149H68.7141C71.2213 105.149 73.3106 103.06 73.3106 100.553V79.6597C73.3106 77.1526 71.2213 75.0633 68.7141 75.0633H56.5963Z"
      fill="#444B54"
    />
    <path
      d="M90.8606 84.9526H83.8267C82.1552 84.9526 80.832 83.6294 80.832 81.9579V68.3079C80.832 66.6365 82.1552 65.3133 83.8267 65.3133H90.8606C92.532 65.3133 93.8552 66.6365 93.8552 68.3079V81.9579C93.8552 83.6294 92.532 84.9526 90.8606 84.9526Z"
      fill="#D22222"
    />
    <path
      d="M91.5568 94.3544H82.9907C81.8068 94.3544 80.9014 93.449 80.9014 92.2651C80.9014 91.0811 81.8068 90.1758 82.9907 90.1758H91.5568C95.0389 90.1758 97.8943 87.3204 97.8943 83.8383V66.4276C97.8943 62.9454 95.0389 60.0901 91.5568 60.0901H82.9907C79.5085 60.0901 76.6532 62.9454 76.6532 66.4276C76.6532 67.6115 75.7478 68.5169 74.5639 68.5169C73.38 68.5169 72.4746 67.6115 72.4746 66.4276C72.4746 60.6472 77.2103 55.9115 82.9907 55.9115H91.5568C97.3371 55.9115 102.073 60.6472 102.073 66.4276V83.8383C102.073 89.6883 97.3371 94.3544 91.5568 94.3544Z"
      fill="#444B54"
    />
    <path
      d="M68.206 92.4044C67.0221 92.4044 66.1167 91.499 66.1167 90.3151V82.6544C66.1167 81.4704 67.0221 80.5651 68.206 80.5651C69.3899 80.5651 70.2953 81.4704 70.2953 82.6544V90.3151C70.2953 91.499 69.3899 92.4044 68.206 92.4044Z"
      fill="#444B54"
    />
    <path
      d="M62.6552 75.0633C61.5409 75.0633 60.5659 74.1579 60.5659 72.974L60.2873 46.649C60.2177 42.5401 61.8195 38.7097 64.6748 35.7847C67.5302 32.9294 71.3606 31.3276 75.4695 31.3276C83.8266 31.3276 90.6516 38.1526 90.6516 46.5097V58.0008C90.6516 59.1847 89.7463 60.0901 88.5623 60.0901C87.3784 60.0901 86.4731 59.1847 86.4731 58.0008V46.5097C86.4731 40.4508 81.5284 35.5061 75.4695 35.5061C72.4748 35.5061 69.6891 36.6901 67.5998 38.7794C65.5106 40.8686 64.3963 43.6544 64.3963 46.649L64.6748 72.974C64.7445 74.0883 63.8391 75.0633 62.6552 75.0633Z"
      fill="#444B54"
    />
    <defs>
      <filter
        id="filter0_d"
        x="0.821289"
        y="0.327576"
        width="156"
        height="156"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dx="4" dy="8" />
        <feGaussianBlur stdDeviation="9" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

ListeningSkill.propTypes = {
  className: PropTypes.string,
};

ListeningSkill.defaultProps = {
  className: '',
};

export default ListeningSkill;
