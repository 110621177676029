import React from 'react';

import VersionOrg from '@dolstaff/shared/es/Version';

const Version = () => {
  return (
    <VersionOrg
      reactAppEnv={process.env.GATSBY_ENV}
      reactAppCurrentGitSha={process.env.GATSBY_CURRENT_GIT_SHA}
      reactAppBuildTime={process.env.GATSBY_BUILD_TIME}
    />
  );
};

export default Version;
