import React from 'react';
import PropTypes from 'prop-types';

const RightArrow = ({ className, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest} className={className} fill="none" viewBox="0 0 7 12">
    <path d="M1 1L5 6.38462L1 11" stroke="currentColor" strokeWidth="2" />
  </svg>
);

RightArrow.propTypes = {
  className: PropTypes.string,
};

RightArrow.defaultProps = {
  className: '',
};

export default RightArrow;
